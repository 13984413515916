.main-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 25px;
    flex-direction: row;
    /* border: 0px; */
}

.card {
    height: 100%;
    width: 1000px;
    box-shadow: 0px 5px 9px -5px #33333360;
    border-radius: 8px;
}

.card-info {
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.card-info>.name {
    width: 100px;
    height: 30px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}
.yellow-card>.card-info>.name{
    background-color: var(--y-90);
    color: var(--y-20);
}
.blue-card>.card-info>.name{
    background-color: var(--powder-blue);
    color: var(--ocean-blue);
}
.green-card>.card-info>.name{
    background-color: var(--light-aqua);
    color: var(--dark-green);
}

.card-info>h2 {
    text-align: left;
    font-size: 40px;
    padding-top: 20px;
}
.yellow-card>.card-info>h2{
    color: var(--y-40);
}
.blue-card>.card-info>h2{
    color: var(--ocean-blue);
}
.green-card>.card-info>h2{
    color: var(--jungle-green);
}
.card-info>p {
    color: #808080;
    text-align: left;
    font-size: 17px;
    font-family: work sans;
    width: 630px;
}

.card-info>ul {
    width: 100%;
    display: grid;
    padding-top: 30px;
    padding-left: 0px;
    row-gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.card-info>ul>li {
    list-style: square inside;
    text-align: left;
}
.yellow-card>.card-info>ul>li {
    color: var(--y-40);
}
.blue-card>.card-info>ul>li {
    color: var(--ocean-blue);
}
.green-card>.card-info>ul>li {
    color: var(--jungle-green);
}
.card-info>ul>li>span {
    color: var(--dark-blue);
    display: inline-block;
}

.card>.card-info>.card-button {
    width: 280px;
    margin-top: auto;
}

.main-card>.card-image {
    height: 100%;
}

.main-card>.card-image>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

@media (min-width : 0px) and (max-width : 470px) {
    .main-card{
        width: 100%;
        flex-direction: column-reverse;
    }
    .card{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 30px 20px;
    }
    .card-info{
        padding: 0px;
    }
    .card-info>h2{
        font-size: 30px;
    }
    .card-info>p{
        font-size: 18px;
        width: 100%;
    }
    .card>.card-button {
        width: 100%;
        position: relative;
        left: 0px;
        bottom: 0px;
    }
    .card>.card-button>a {
        width: 100%;
        display: block;
    }
    .card>.card-button>a>button {
        width: 100%;
    }
    .card-info>ul {
       width: 100%;
       display: flex;
       flex-direction: column;
       gap: 30px;
       padding-top: 0px;
       flex-wrap: nowrap;
       height: auto;
    }
    .card-info>ul>li>p {
        width: 100%;
    }
}
@media (min-width : 470px) and (max-width : 1300px) {
    .main-card{
        width: 100%;
        flex-direction: column-reverse;
        gap: 60px;
    }
    .card{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 30px 20px;
    }
    .card-info{
        padding: 0px;
    }
    .card-info>h2{
        font-size: 30px;
    }
    .card-info>p{
        font-size: 18px;
        width: 100%;
    }
    .card>.card-button {
        width: 100%;
        position: relative;
        left: 0px;
        bottom: 0px;
        display: flex;
        justify-content: center;
        
    }
    .card>.card-button>a {
        width: 405px;
        display: block;
    }
    .card>.card-button>a>button {
        width: 100%;
    }
    .card-info>ul {
       width: 100%;
       display: flex;
       flex-direction: column;
       gap: 30px;
       padding-top: 0px;
    }
    .card-info>ul>li>p {
        width: 100%;
    }
    .main-card>.card-image {
        width: 100%;
        height: 460px;
    }
    
}