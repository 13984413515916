.tags{
    padding: 20px 200px;
}
.tags>h3 {
    color: var(--dark-cyan);
    text-align: left;
    font-size: 25px;
    line-height: 1.2;
    padding-bottom: 30px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.tags>.taglist {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.tags>.taglist>div {
    padding: 0px 20px;
    height: 35px;
    color: var(--dark-cyan);
    background-color: var(--powder-blue);
    border: 2px solid transparent;
    border-radius: 45px;
    font-size: 14px;
    font-weight: 500;
    transition: 0.3s ease all;
    display: flex;
    align-items: center;
}

.tags>.taglist>div:hover {
    background-color: var(--ocean-blue);
    border: 2px solid var(--ocean-blue);
    color: var(--powder-blue);
}

@media (min-width : 0px) and (max-width : 470px){
    .tags{
        padding: 30px 20px;
    }
    .tags>h3 {
        font-size: 18px;
    }
    .tags>.taglist {
        flex-wrap: wrap;
        gap: 10px;
    }
    
}
@media (min-width : 470px) and (max-width : 1300px){
    .tags{
        padding: 30px 70px;
    }
    .tags>h3 {
        font-size: 24px;
    }
    .tags>.taglist {
        flex-wrap: wrap;
        gap: 10px;
    }
    
}