.product {
    padding: 60px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.product>.product-content {
    padding: 30px 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
@media (min-width : 0px) and (max-width : 470px) {
    .product{
        gap: 0px;
    }
    .product>.product-content {
        padding: 45px 20px;
    }
}
@media (min-width : 470px) and (max-width : 1300px) {
    .product{
        padding-top: 45px;
        gap: 60px;
    }
    .product>.product-content {
        padding: 0px 70px;
    }
}