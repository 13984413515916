.plan {
    display: flex;
    flex-direction: column;
    /* width: 460px; */
    border-radius: 6px;
    /* border: 1px solid var(--light-gray); */
    padding: 30px;
    gap: 30px;
    box-shadow: 0px 5px 9px 2px #33333318;
    /* box-shadow: -2px 5px 9px -5px #33333360; */
}

.plan.basic-plan {
    background: linear-gradient(150deg, var(--powder-blue) 0%, #ffffff 20%);
}

.plan.medium-plan {
    background: linear-gradient(150deg, var(--light-aqua) 0%, #ffffff 20%);
}

.plan.premium-plan {
    background: linear-gradient(150deg, var(--y-90) 0%, #ffffff 20%);
}

.plan>.plan-item {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.plan>.plan-item>.plan-type {
    padding: 10px 30px;
    font-size: 17px;
    border-radius: 4px;
    align-self: baseline;
    margin-bottom: 30px;
}

.basic-plan>.plan-item>.plan-type {
    color: var(--powder-blue);
    background-color: var(--ocean-blue);
}

.medium-plan>.plan-item>.plan-type {
    color: var(--light-aqua);
    background-color: var(--jungle-green);
}

.premium-plan>.plan-item>.plan-type {
    color: var(--y-90);
    background-color: var(--y-40);
}

.basic-plan>.plan-item>.ovoky-text-style-title-H2 {
    color: var(--light-blue);
    text-align: left;
    margin-bottom: 20px;
}

.medium-plan>.plan-item>.ovoky-text-style-title-H2 {
    color: var(--green-cyan);
    text-align: left;
    margin-bottom: 20px;
}

.premium-plan>.plan-item>.ovoky-text-style-title-H2 {
    color: var(--y-50);
    text-align: left;
    margin-bottom: 20px;
}

.basic-plan>.plan-item>.descp {
    color: #004466CC;
    text-align: left;
    width: 100%;
    margin-bottom: 40px;
}

.medium-plan>.plan-item>.descp {
    color: #006644CC;
    text-align: left;
    width: 100%;
    margin-bottom: 40px;
}

.premium-plan>.plan-item>.descp {
    color: #664400E6;
    text-align: left;
    width: 100%;
    margin-bottom: 40px;
}

.plan-vertical>.plan-item>.plan-line {
    width: 100%;
    height: 3px;
    margin-bottom: 40px;
}

.basic-plan>.plan-item>.plan-line {
    background-color: var(--sky-blue);
}

.medium-plan>.plan-item>.plan-line {
    background-color: #ADEBD6;
}

.premium-plan>.plan-item>.plan-line {
    background-color: var(--y-80);
}

.plan-vertical>.plan-item>.items {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.plan>.plan-item>.items>.item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    text-align: left;
    gap: 10px;
}

.plan-vertical>.plan-item>.items>.item>p {
    padding: 8px 14px;
    font-size: 17px;
    border-radius: 4px;
    align-self: baseline;
    font-weight: 500;
}

.basic-plan>.plan-item>.items>.item>p {
    color: var(--dark-cyan);
    background-color: var(--powder-blue);
}

.medium-plan>.plan-item>.items>.item>p {
    color: var(--dark-green);
    background-color: var(--light-aqua);
}

.premium-plan>.plan-item>.items>.item>p {
    color: var(--y-20);
    background-color: var(--y-90);
}

.plan-vertical>.plan-item>.items>.item>.item-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 12px;
    gap: 16px;
    border-radius: 4px;
}

.basic-plan>.plan-item>.items>.item>.item-content {
    background-color: var(--b-95);
}

.medium-plan>.plan-item>.items>.item>.item-content {
    background-color: var(--g-95);
}

.premium-plan>.plan-item>.items>.item>.item-content {
    background-color: #FFF6E5;
}

.plan-vertical>.plan-item>.items>.item>.item-content>ul {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: flex-start;
    padding-left: 20px;
}
.basic-plan>.plan-item>.items>.item>.item-content>ul{
    list-style-image: url("../assets/b-rectangle.svg");
}
.medium-plan>.plan-item>.items>.item>.item-content>ul{
    list-style-image: url("../assets/rectangle.svg");
}
.premium-plan>.plan-item>.items>.item>.item-content>ul{
    list-style-image: url("../assets/y-rectangle.svg");
}
.basic-plan>.plan-item>.items>.item>.item-content>li {
    color: var(--light-blue);
    align-self: flex-start;
    padding: 10px;
    background-color: #1F7EAD26;
    border-radius: 4px;
}

.basic-plan>.plan-item>.items>.item>.item-content>li>span {
    color: var(--dark-cyan);
}

.basic-plan>.plan-item>.items>.item>.item-content>ul>li {
    color: var(--light-blue);
}

.basic-plan>.plan-item>.items>.item>.item-content>ul>li>span {
    color: var(--dark-cyan);
}
.medium-plan>.plan-item>.items>.item>.item-content>li {
    color: var(--light-blue);
    align-self: flex-start;
    padding: 10px;
    background-color: #17825E26;
    border-radius: 4px;
}

.medium-plan>.plan-item>.items>.item>.item-content>li>span {
    color: var(--dark-green);
}
.medium-plan>.plan-item>.items>.item>.item-content>ul>li {
    color: #5CD6AD;
}

.medium-plan>.plan-item>.items>.item>.item-content>ul>li>span {
    color: var(--dark-green);
}
.premium-plan>.plan-item>.items>.item>.item-content>li {
    color: var(--light-blue);
    align-self: flex-start;
    padding: 10px;
    background-color: #99660026;
    border-radius: 4px;
}

.premium-plan>.plan-item>.items>.item>.item-content>li>span {
    color: var(--y-20);
}

.premium-plan>.plan-item>.items>.item>.item-content>ul>li {
    color: var(--y-60);
}

.premium-plan>.plan-item>.items>.item>.item-content>ul>li>span {
    color: var(--y-20);
}

.plan-vertical>.plan-item>.items>.item>.item-content>p {
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
}

.basic-plan>.plan-item>.items>.item>.item-content>p {
    background-color: #1F7EAD26;
    color: var(--dark-cyan);
}

.medium-plan>.plan-item>.items>.item>.item-content>p {
    background-color: #17825E26;
    color: var(--dark-green);
}

.premium-plan>.plan-item>.items>.item>.item-content>p {
    background-color: #99660026;
    color: var(--y-20);
}

.plan-vertical>.plan-item>.items>.item>.item-content>.plan-line {
    height: 3px;
    flex-shrink: 0;
    border-radius: 8px;
}

.plan-vertical>.plan-item>.items>.numbers>.item-content>.plan-line:last-child {
    display: none;
}

.basic-plan>.plan-item>.items>.item>.item-content>.plan-line {
    background-color: #B7D2E0;
}

.medium-plan>.plan-item>.items>.item>.item-content>.plan-line {
    background-color: #B7D9DA;
}

.premium-plan>.plan-item>.items>.item>.item-content>.plan-line {
    background-color: #E0D2B7;
}

.plan-vertical>.plan-button {
    margin-top: auto;
}






































/* Horizontal plan */
.plan-horizontal {
    grid-column: 1 / span 3;
    overflow-x: hidden;
}

.plan-horizontal>.plan-item>p {
    color: #00446680;
    font-size: 17px;
    text-align: left;
}

.plan-horizontal>.plan-item>.items {
    display: flex;
    justify-content: space-between;
}

.plan-horizontal>.plan-item>.items>.item {
    width: 30%;
}

.plan-horizontal>.plan-item>.items>.item>p {
    padding: 6px 12px;
    font-size: 17px;
    border-radius: 4px;
    align-self: baseline;
    background-color: var(--g-90);
    color: var(--g-20);
    font-weight: 500;
}

.plan-horizontal>.plan-item>.items>.item>.item-content {
    background-color: #F2F2F2;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 12px;
    gap: 16px;
    border-radius: 4px;
    color: var(--g-20);
}

.plan-horizontal>.plan-item>.items>.item>.item-content>.plan-line {
    width: 100%;
    height: 3px;
    background-color: #CCCCCC;
    flex-shrink: 0;
}

.plan-horizontal>.plan-item>.items>.item>.item-content>ul {
    padding-left: 14px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    list-style: square;
    align-items: flex-start;
}

.plan-horizontal>.plan-item>.items>.item>.item-content>ul>li {
    color: var(--g-60);
}

.plan-horizontal>.plan-item>.items>.item>.item-content>ul>li>span {
    color: var(--g-20);
}

.plan-horizontal>.plan-item>.items>.item>.item-content>ul>p {

    background-color: #66666626;
    padding: 4px 12px;
    border-radius: 4px;
    transform: translateX(-15px);
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
}

.plan-horizontal>.plan-button {
    width: 418px;
    align-self: center;
}

.plan-horizontal>.plan-item>.items>.item>span {
    display: none;
}


@media screen and (max-width : 400px) {
    .plan-horizontal {
        grid-column: 1;
        overflow-x: hidden;
    }

    .plan-horizontal>.plan-item>p {
        padding: 6px 12px;
        font-size: 17px;
        border-radius: 4px;
        align-self: baseline;
        background-color: var(--bg-categ-color);
        color: var(--categ-color);
        font-weight: 500;
    }

    .plan-horizontal>.plan-item>.items {
        display: flex;
        flex-direction: column;
        gap: 38px;
    }


    .plan-horizontal>.plan-item>.items>.item {
        width: 100%;
    }

    .plan-horizontal>.plan-item>.items>.item>.item-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 16px 12px;
        gap: 16px;
        border-radius: 4px;
        color: var(--g-20);
        background-color: #F2F2F2;
    }

    .plan-horizontal>.plan-item>.items>.item>.item-content>.plan-line {
        border-radius: 8px;
    }

    .plan-horizontal>.plan-button {
        margin-top: auto;
        width: 100%;
    }

}