.fast-a-q {
    padding: 60px 0px 100px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    max-width: 100%;
}

.show-parag {
    border: 1px solid var(--sky-blue);
    border-radius: 0px 0px 8px 8px;
    border-top: 0px;
    /* width: 100%; */
    padding: 30px
}

.show-parag>p {
    color: var(--dark-blue);
    text-align: left;
    font-size: 20px;
}

.accordions-container {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    width: 100%;
    /* flex-shrink: 0; */
}

.faq-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 60px;
    padding: 0px 200px;

}

.faq-container>.faq-title {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-top: 20px;
    width: 40%;
    flex-shrink: 0;
}

.faq-container>.faq-title>div:first-child {
    text-align: left;
    color: var(--dark-cyan);
}

.faq-container>.faq-title>p {
    color: var(--dark-cyan);
    text-align: left;
}


@media (min-width : 0px) and (max-width : 470px) {
    .fast-a-q{
        padding: 45px 0px;
    }
    .faq-container{
        width: 100%;
        flex-direction: column;
        padding: 20px;
        gap: 45px;
    }
    .faq-container>.faq-title{
        gap: 45px;
        width: 100%;
    }
    .faq-container>.faq-title>.big-title{
        font-size: 24px;
    }
    .faq-container>.faq-title>.parag{
        font-size: 14px;
    }
    .show-parag {
        padding: 15px
    }
    .show-parag>p {
        font-size: 14px;
        line-height: 1.5;
    }
    
}
@media (min-width : 470px) and (max-width : 1300px) {
    .fast-a-q{
        padding: 40px 0px ;
        gap: 0px;
    }
    .faq-container{
        width: 100%;
        flex-direction: column;
        padding: 40px 70px 0px 70px;
        gap: 45px;
    }
    .faq-container>.faq-title{
        gap: 40px;
        width: 100%;
        margin-top: 0px;
    }
    .faq-container>.faq-title>.big-title{
        font-size: 46px;
    }
    .faq-container>.faq-title>.parag{
        font-size: 16px;
    }
    .show-parag {
        padding: 15px
    }
    .show-parag>p {
        font-size: 16px;
        line-height: 1.5;
    }
}