.reviews-loop {
    display: flex;
    gap: 20px;
    padding: 0px 50px;
    justify-content: center;
}

.reviews-loop>.review {
    padding: 35px 40px;
    border: 1px solid #ADEBD6;
    background: linear-gradient(-150deg, var(--light-aqua) 0%, white 45%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.reviews-loop>.review>.person-info {
    display: flex;
    padding-bottom: 30px;
    align-items: center;
    gap: 16px;
}

.reviews-loop>.review>.person-info>img {
    width: 60px;
    height: 60px;
}

.reviews-loop>.review>.person-info>div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 7px;
}

.reviews-loop>.review>.person-info>div>p {
    text-align: left;
    font-size: 20px;
    line-height: 1.5;
}

.reviews-loop>.review>.person-info>div>img {
    width: 110px;
    height: 18px;
}

.reviews-loop>.review>.comment>p {
    text-align: left;
    font-size: 17px;
    /* min-width: 300px; */
    font-weight: 300;
    line-height: 1.5;
}
@media (min-width : 0px) and (max-width : 470px){
    .reviews-loop {
        display: flex;
        gap: 16px;
        flex-direction: column;
        padding: 0px 20px;
    }
    .reviews-loop>.review>.comment>p {
        text-align: left;
        font-size: 17px;
        /* width: 300px; */
    }
}
@media (min-width : 470px) and (max-width : 1300px){
    .reviews-loop {
        display: flex;
        gap: 16px;
        flex-direction: column;
        padding: 0px 70px;
    }
    .reviews-loop>.review>.comment>p {
        text-align: left;
        font-size: 17px;
        /* min-width: 300px; */
    }
}