.not-found {
    height: 653px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.not-found>h1 {
    color: var(--azure);
    font-size: 80px;
    align-self: center;
}