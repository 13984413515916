.calendar {
    width:906px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.calendar>.months-container {
    display: flex;
    gap: 120px;
    justify-content: flex-start;
}

.calendar>.months-container>.month>.weekdays {
    display: flex;
    flex-wrap: wrap;
    gap: 28px;
    color: #333333;
}

.calendar>.months-container>.month>.days {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    color: var(--g-60);
}

.calendar>.months-container>.month>.days>.day {
    padding: 2px;
    border: 2px solid transparent;
}

.calendar>.months-container>.month>.days>.day:hover {
    background-color: var(--powder-blue);
    border: 2px solid var(--light-blue);
    color: var(--light-blue);
    border-radius: 2px;
}

.calendar>.months-container>.month>.days>.day.active {
    background-color: var(--sky-blue);
    color: var(--electric-blue);
    border-radius: 2px;
}

.calendar>.months-container>.month {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.calendar>.months-container>.month>h2 {
    font-size: 15px;
    font-weight: 400;
    color: var(--powder-blue);
    padding: 9px 15px;
    background-color: var(--dark-blue);
    border-radius: 4px;
}

.calendar>.confirm-btn{
    align-self: baseline;
}
.calendar>.confirm-btn>button{
    height: 34px;
    padding: 0px 15px;
    background-color: var(--jungle-green);
    border-radius: 4px;
    color: var(--light-aqua);
    font-size: 17px;
    transition: 0.3s ease;
    border: 2px solid transparent;
}
.calendar>.confirm-btn>button:hover{
    color: var(--dark-green);
    border: 2px solid var(--green-cyan);
    background-color: var(--aquamarine);
}


@media (min-width : 0px ) and (max-width : 470px) {
    .calendar{
        width: 100%;
    }
}
@media (min-width : 470px ) and (max-width : 1300px) {
    .calendar{
        width: 100%;
    }
    .calendar>.months-container {
        justify-content: flex-start;
        gap: 20px;
    }
}