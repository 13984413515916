.author-card {
    min-width: 300px;
    border: 2px solid var(--jungle-green);
    border-radius: 5px;
    background: linear-gradient(120deg, var(--light-aqua) 0%, #ffffff 50%);
    display: flex;
    padding: 14px;
    gap: 12px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease-in-out background 0.3s ease-in-out;
}
.author-card>img{
    width: 68px;
    height: 69px;
}
.author-card>div>h4 {
    font-size: 18px;
    text-align: left;
    line-height: 1.5;
    font-weight: 400;
    color: var(--g-10);
}

.author-card>div>div {
    font-size: 14px;
    display: flex;
    gap: 9px;
}
.author-card>div>div>p{
    display: flex;
    align-items: center;
    gap: 2px;
    color: var(--g-30);
    font-weight: 500;
}
.author-card>div>div>p>svg{
    color: var(--jungle-green);
}
.author-card:hover{
    border: 2px solid var(--green-cyan);
    background: linear-gradient(120deg, var(--jungle-green) -30%, #ffffff 50%);
}
.author-card:active{
    background: var(--jungle-green);
    border: 2px solid var(--mint-green);
}
.author-card:active div>div>p, .author-card:active div>div>p>svg , .author-card:active div>h4{
    color: white !important;
}