.card-blog {
    display: block;
    position: relative;
    height: 100%;
}

.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    --linear1: linear-gradient(-50deg, #0000 30%, #000 150%);
    --linear2: linear-gradient(-80deg, #0000 70%, #000 190%);
    --linear3: linear-gradient(150deg, #4CC3FF00 80%, #4CC3FF82 130%);
    background: var(--linear1), var(--linear2), var(--linear3);
    transition: transform .3s, visibility .3s ease-in;
}

.card-blog>.card-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 50px;
    position: relative;
    overflow: hidden;
    z-index: 0;
    height: 100%;
    border-radius: 8px;
}

.card-blog>.card-container>.bgImage {
    z-index: -2;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: transform .3s, visibility .3s ease-in;
}

.card-blog>.card-container:hover .overlay {
    --linear1: linear-gradient(-80deg, #0000 100%, #000 100%);
    --linear2: linear-gradient(-50deg, #0000 70%, #000 180%);
    --linear3: linear-gradient(150deg, #4CC3FF00 80%, #4CC3FF82 100%);
    /* --linear4: linear-gradient(0deg, #FFFFFF52 100%, #ffffff00 200%); */
    background: var(--linear1), var(--linear2), var(--linear3);
}

.card-blog>.card-container>.article-info-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    color: white;
    gap: 15px;
    padding: 0px 20px 0px 20px;
}


.card-blog>.card-container>.article-info-container>h3 {
    font-size: 25px;
    line-height: 1.2;
    text-align: left;
    font-weight: 500;
}

.card-blog>.card-container>.author-info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 30px 20px 0px 20px;
}

.card-blog>.card-container>.author-info-container>div>p {
    display: flex;
    align-items: center;
    gap: 5px;
}

.card-blog>.card-container>.author-info-container>div>p>img {
    width: 40px;
    height: 41px;
    border-radius: 50%;
    border: 1px solid var(--light-blue);
    object-fit: cover;
}

.card-blog>.card-container>.reaction {
    display: flex;
    align-self: end;
    color: white;
    background-color: #2F2F2F80;
    border-radius: 8px;

}

.card-blog>.card-container>.reaction.vertical>div {
    height: 76px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    align-items: center;
}

.card-blog>.card-container>.reaction.vertical {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    width: 60px;
    padding: 10px;
    margin: auto 20px 20px 0px;
}

.card-blog>.card-container>.reaction.horizontal {
    flex-direction: row;
    width: 90%;
    justify-content: space-around;
    margin: auto 20px 20px 20px;
    padding: 0;
}

.card-blog>.card-container>.reaction.horizontal>div {
    display: flex;
    flex-direction: row;
    height: 40px;
    gap: 9px;
    align-items: center;
}

.card-blog>.card-container>.reaction>div>svg {
    transform: rotateY(180deg);
}