.blog {
    display: flex;
    width: 100%;
    max-height: 900px;
    background: url(../../public/images/bgImg.png) no-repeat;
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: ease-out;
}
.article-link{
    color: var(--light-blue);
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding-top: 30px;
    transition: 0.3s ease;
}
.article-link:hover{
    color: var(--ocean-blue);
}

@media (min-width : 0px) and (max-width : 470px) {
    .blog{
        /* background-size: 100%; */
        background-position-y: bottom;
    }
    
}