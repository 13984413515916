.one-article {
    display: flex;
    flex-direction: column;
    gap: 80px;
}

/* style for the header of  each article */
.one-article>.one-article-header {
    width: 100%;
    height: 100%;
    background: url(/public/images/bg-image-article.png) no-repeat;
    position: relative;
    margin-top: -880px;
}



/* style for sticky div */
.one-article>.sticky {
    position: sticky;
    top: 40px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 30px;
    height: 800px;
    justify-content: space-around;
    pointer-events: none;
    z-index: 1;
}

.one-article>.sticky>div {
    pointer-events: all;
    transform: translateX(-80px);
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 114px;
    align-items: flex-end;
}

.one-article>.sticky>div>div {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    transition: 0.3s ease;
    cursor: pointer;
    background-color: white;
    gap: 5px;
}

.one-article>.sticky>div>.save-img {
    color: var(--light-blue);
    background-color: white;
    border: 1px solid #E6EDF0;
    transition: 0.3s;
    display: flex;

}

.one-article>.sticky>div>.save-img>span {
    display: none;
    transition: 0.3s ease;
}

.one-article>.sticky>div>.save-img:hover {
    background-color: var(--powder-blue);
    color: var(--dodger-blue);
    cursor: pointer;
    width: 114px;
}

.one-article>.sticky>div>.save-img:hover span {
    display: block;
}

.one-article>.sticky>div>.save-img.active {
    background-color: var(--ocean-blue);
    color: var(--powder-blue);
    cursor: pointer;
}

.one-article>.sticky>div>.save-img.active:hover {
    background-color: var(--dark-blue);
    color: var(--light-azure);
    cursor: pointer;
    width: 114px;
}

.one-article>.sticky>div>.save-img.active:hover span {
    display: block;
}

.one-article>.sticky>div>.copie-div {
    border: 1px solid var(--silver-gray);
    justify-content: center;
    align-items: center;
}

.one-article>.sticky>div>.copie-div>span {
    display: none;
}

.one-article>.sticky>div>.copie-div:hover {
    background-color: var(--g-60);
    width: 114px;
}

.one-article>.sticky>div>.copie-div:hover span {
    display: block;
}

.one-article>.sticky>div>.like-btn {
    color: var(--red-like);
    border: 1px solid var(--silver-gray);
}

.one-article>.sticky>div>.like-btn>span {
    display: none;
}

.one-article>.sticky>div>.like-btn:hover {
    background-color: #FFE1E1;
    color: #AB102C;
    width: 114px;
}

.one-article>.sticky>div>.like-btn:hover span {
    display: block;
}

.one-article>.sticky>div>.like-btn.active {
    background-color: #FF284F;
    color: white;
}

.one-article>.sticky>div>.like-btn.active:hover {
    background-color: #B30C2A;
    color: white;
}

.one-article>.sticky>div>.share-btn {
    color: var(--jungle-green);
    align-items: center;
    justify-content: center;
    border: 1px solid var(--silver-gray);
    background-color: white;
}

.one-article>.sticky>div>.share-btn>span {
    display: none;

}

.one-article>.sticky>div>.share-btn:hover {
    background-color: var(--light-aqua);
    width: 114px;

}

.one-article>.sticky>div>.share-btn:hover span {
    display: block;
}

.one-article>.sticky>div>.share-btn.active {
    background-color: var(--jungle-green);
    color: white;
}

.one-article>.sticky>div>.share-btn.active:hover {
    background-color: var(--dark-green);
    color: white;
}



/*  */
.one-article>.one-article-header {
    padding: 140px 200px 50px 200px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
}


.line {
    width: 1px;
    height: 42px;
    background-color: #00446610;
    display: none;
}

.line.active {
    width: 1px;
    height: 42px;
    background-color: #CCEEFF20;
}

.one-article>.one-article-header>.one-article-info {
    color: white;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.one-article>.one-article-header>.one-article-info>.parag {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.one-article>.one-article-header>.one-article-info>.parag>p {
    color: white;
}

.one-article>.one-article-header>.one-article-info>h1 {
    font-size: 64px;
    line-height: 1.2;
    font-weight: 500;
}

.one-article>.one-article-header>.one-article-info>h3 {
    font-size: 25px;
    line-height: 1.2;
    font-weight: 500;
    color: white;
}

.one-article>.one-article-header>.one-article-foot {
    display: flex;
    justify-content: space-between;

}

.one-article>.one-article-header>.one-article-foot>div {
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
}

.one-article>.one-article-header>.one-article-foot>div>img {
    width: 40px;
    height: 40px;
}

.one-article>.one-article-header>.one-article-foot>div:first-child {
    background-color: #00446640;
    padding: 10px 14px;
    border-radius: 8px;
    transition: 0.3s ease;
}

.one-article>.one-article-header>.one-article-foot>div:first-child:hover {
    background-color: #00446680;
}

.one-article>.one-article-header>.one-article-foot>div:first-child>p {
    color: white;
}



/* style for the body of  each article */

.one-article>.one-article-body {
    display: flex;
    flex-direction: column;
    gap: 60px;
    text-align: left;
    padding: 0px 200px;
    position: relative;
}

.one-article>.one-article-body>.nav-bar-vertical {
    /* start: test */
    display: none;
    /* end: test */
    position: sticky;
    align-items: center;
    top: 200px;
    width: 50px;
    transform: translate(-100px, 100px);
    display: flex;
    justify-content: center;
}

.one-article>.one-article-body>.nav-bar-vertical>div {
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 300px;
}

.one-article>.one-article-body>.nav-bar-vertical>div>div {
    width: 14px;
    height: 14px;
    background-color: var(--g-80);
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.one-article>.one-article-body>.nav-bar-vertical>div>div>span {
    position: absolute;
    left: -53px;
    padding: 4px;
    background-color: var(--g-30);
    border-radius: 5px;
    color: var(--g-90);
    transition: 0.3s ease;
    display: none;
}

.one-article>.one-article-body>.nav-bar-vertical>div>div>a {
    width: 8px;
    height: 8px;
    background-color: var(--g-60);
    border-radius: 50%;
    align-self: center;
    transition: 0.3s ease;
    cursor: pointer;
}

.one-article>.one-article-body>.nav-bar-vertical>div>div:hover span {
    display: block;
}

.one-article>.one-article-body>.nav-bar-vertical>div>div.active {
    background-color: #134E6B;
}

.one-article>.one-article-body>.nav-bar-vertical>div>div.active span {
    background-color: #134E6B;
}

.one-article>.one-article-body>.nav-bar-vertical>div>div>a.active {
    background-color: var(--dark-navy);
}

.one-article>.one-article-body>.nav-bar-vertical>div>div>a.active:hover {
    background-color: white;
}



.one-article h3 {
    color: var(--g-10);
    font-size: 25px;
    line-height: 1.2;
    font-weight: 500;
}

.one-article p {
    color: var(--g-10);
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    text-align: justify;
}

.one-article>.one-article-body>.article-body-container {
    margin-top: -300px;
    gap: 60px;
    display: flex;
    flex-direction: column;
}

.one-article>.one-article-body>.article-body-container>.intro {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}
.one-article>.one-article-body>.article-body-container>.descp-article {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.one-article>.one-article-body>.article-body-container>.tips {
    display: flex;
    flex-direction: row;
    gap: 60px;
}

.one-article>.one-article-body>.article-body-container>.tips>div:first-child {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.one-article>.one-article-body>.article-body-container>.tips>div:first-child>ul {
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    list-style: decimal;
}

.one-article>.one-article-body>.article-body-container>.tips>div:first-child>ul>div:nth-of-type(2) {
    background-color: var(--sky-blue);
    color: var(--dark-navy);
}

.one-article>.one-article-body>.article-body-container>.tips>div:first-child>ul>div {
    background-color: var(--powder-blue);
    padding: 20px 40px;
    border-radius: 8px;
    font-size: 20px;
    line-height: 1.5;
    color: var(--ocean-blue);
    text-align: justify;
}

.one-article>.one-article-body>.article-body-container>.tips>.images {
    display: flex;
    flex-direction: column;
    width: 750px;
    gap: 20px;
    position: relative;
    height: 660px;
    flex-shrink: 0;
}

.one-article>.one-article-body>.article-body-container>.tips>.images>.fist-bg {
    position: absolute;
    top: 0;
    right: 0px;
    opacity: 0.4;
    z-index: -2;
}

.one-article>.one-article-body>.article-body-container>.tips>.images>.second-bg {
    position: absolute;
    top: 27px;
    right: 45px;
    opacity: 0.8;
    z-index: -1;
}

.one-article>.one-article-body>.article-body-container>.tips>.images>.third-bg {
    position: absolute;
    top: 54px;
    right: 90px;
    width: 590px;
    height: 597px;
    opacity: 1;
    z-index: 0;
    overflow: hidden;
    border-radius: 8px
}

.one-article>.one-article-body>.article-body-container>.tips>.images>.third-bg:hover img {
    transform: scale(1.1);
    filter: grayscale(0%);
}

.one-article>.one-article-body>.article-body-container>.tips>.images>div>img {
    width: 590px;
    height: 590px;
    transition: 0.3s ease;
    filter: grayscale(40%);
}

.one-article>.one-article-body>.article-body-container>.capturing {
    display: flex;
    flex-direction: row;
    gap: 60px;
    align-items: center;
}

.one-article>.one-article-body>.article-body-container>.capturing>img {
    width: 772px;
    height: 597px;
}

.one-article>.one-article-body>.article-body-container>.capturing>.div {
    width: 50%;
    text-align: left;
}

.one-article>.one-article-body>.article-body-container>.whats-next>ul {
    padding-left: 20px;
}

.one-article>.one-article-body>.article-body-container>.whats-next>ul>li {
    color: var(--g-10);
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    text-align: justify;
    list-style-image: url("../assets/square.svg");
}

@media (min-width : 0px) and (max-width : 470px) {
    .one-article {
        gap: 20px;
    }
    .one-article>.one-article-header {
        padding: 170px 20px 50px 20px;
        display: flex;
        flex-direction: column;
        gap: 50px;
        width: 100%;
        margin-top: 0px;
    }

    .one-article>.sticky {
        position: absolute;
        top: 570px;
        flex-direction: column-reverse;
        gap: 80px;
        height: 110px;
        right: 30px;
    }
    .one-article>.sticky>div {
        transform: translateX(0px);
        width: 100%;
    }
    .one-article>.sticky>div:first-child {
        transform: translateX(0px);
        /* width: 100%; */
        flex-direction: row;
    }
    .one-article>.one-article-header>.one-article-info{
        gap: 40px;
    }
    .one-article>.one-article-header>.one-article-info>.parag>p {
        color: white;
        font-size: 14px;
    }

    .one-article>.one-article-header>.one-article-info>h1 {
        font-size: 30px;
    }

    .one-article>.one-article-header>.one-article-info>h3 {
        font-size: 20px;
    }

    .one-article>.one-article-header>.one-article-foot>div {
        padding: 7px 8px;
    }
    
    .one-article>.one-article-header>.one-article-foot>div>img {
        width: 30px;
        height: 30px;
    }
    .one-article>.one-article-header>.one-article-foot>div>p {
        font-size: 14px;
    }



    /* body style phone version */

    .one-article>.one-article-body {
        gap: 20px;
        padding: 40px 20px;
    }
    
    .one-article>.one-article-body>.nav-bar-vertical {
        display: none;
    }
    .one-article h3 {
        color: var(--g-10);
        font-size: 20px;
        line-height: 1.2;
        font-weight: 500;
    }
    
    .one-article p {
        color: var(--g-10);
        font-size: 14px;
        line-height: 1.5;
        font-weight: 400;
        text-align: justify;
    }
    
    .one-article>.one-article-body>.article-body-container {
        margin-top: 0px;
        gap: 45px;
        display: flex;
        flex-direction: column;
    }
    .one-article>.one-article-body>.article-body-container>.tips{
        flex-direction: column;
    }
    .one-article > .one-article-body > .article-body-container > .tips > div:first-child > ul{
        padding-left: 0px;
    }
    .one-article>.one-article-body>.article-body-container>.tips>div:first-child>ul>div {
        padding: 15px 30px;
        font-size: 14px;  
    }
    .one-article>.one-article-body>.article-body-container>.tips>.images {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        position: relative;
        height: 225px;
        flex-shrink: 0;
    }
    
    .one-article>.one-article-body>.article-body-container>.tips>.images>.fist-bg {
        display: none;
    }
    
    .one-article>.one-article-body>.article-body-container>.tips>.images>.second-bg {
        display: none;
    }
    
    .one-article>.one-article-body>.article-body-container>.tips>.images>.third-bg {
        width: 100%;
        top: 0px;
        right: 0px;
        height: 100%;
    }
    .one-article>.one-article-body>.article-body-container>.tips>.images>.third-bg>img{
        width: 100%;
        height: 100%;
    }
    .one-article>.one-article-body>.article-body-container>#capturing{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .one-article>.one-article-body>.article-body-container>.capturing {
        display: flex;
        flex-direction: row;
        gap: 60px;
        align-items: center;
    }
    
    .one-article>.one-article-body>.article-body-container>.capturing>img {
        width: 100%;
        height: 225px;
    }
    
    .one-article>.one-article-body>.article-body-container>.capturing>div {
        display: none;
    }
    .one-article>.one-article-body>.article-body-container>#conclusion{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .one-article>.one-article-body>.article-body-container>.whats-next>ul>li {
        font-size: 14px;
    }
}

@media (min-width : 470px ) and (max-width : 1300px) {
    
    .one-article{
        gap: 30px;
    }
    .one-article>.one-article-header {
        padding: 100px 70px 0px 70px;
        gap: 50px;
    }
    .one-article p{
        font-size: 16px;
    }
    .one-article h3{
        font-size: 24px;
    }
    
    .one-article > .one-article-header > .one-article-info > .parag > p{
        font-size: 16px;
    }
    .one-article > .one-article-header > .one-article-info > h1{
        font-size: 46px;
    }
    .one-article > .one-article-header > .one-article-info > h3{
        font-size: 24px;
    }
    .one-article > .one-article-header > .one-article-foot{
        padding-bottom: 30px;
    }
    .one-article > .one-article-body{
        padding: 0px 70px;
    }
    .one-article > .one-article-body > .nav-bar-vertical{
        transform: translate(-60px, 100px);
    }

    .one-article > .one-article-body > .article-body-container > .tips{
        flex-direction: column;
    }
    .one-article>.one-article-body>.article-body-container>.tips>.images {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        position: relative;
        height: 427px;
        flex-shrink: 0;
    }
    
    .one-article>.one-article-body>.article-body-container>.tips>.images>.fist-bg {
        display: none;
    }
    
    .one-article>.one-article-body>.article-body-container>.tips>.images>.second-bg {
        display: none;
    }
    
    .one-article>.one-article-body>.article-body-container>.tips>.images>.third-bg {
        width: 100%;
        top: 0px;
        right: 0px;
        height: 100%;
    }
    .one-article>.one-article-body>.article-body-container>.tips>.images>.third-bg>img{
        width: 100%;
        height: 100%;
    }
    .one-article>.one-article-body>.article-body-container>#capturing{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .one-article>.one-article-body>.article-body-container>.capturing {
        display: flex;
        flex-direction: row;
        gap: 60px;
        align-items: center;
    }
    
    .one-article>.one-article-body>.article-body-container>.capturing>img {
        width: 100%;
        height: 427px;
    }
    
    .one-article>.one-article-body>.article-body-container>.capturing>div {
        display: none;
    }
    .one-article>.one-article-body>.article-body-container>#conclusion{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .one-article>.one-article-body>.article-body-container>.whats-next{
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .one-article>.one-article-body>.article-body-container>.whats-next>ul{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .one-article>.one-article-body>.article-body-container>.whats-next>ul>li {
        font-size: 16px;
    }
}