$color_1: var(--ocean-blue);
$color_2: var(--dark-cyan);
$color_3: var(--powder-blue);
$color_4: var(--light-azure);
$color_5: var(--light-gray);
$color_6: var(--dark-navy);
$color_7: var(--dark-blue);
$color_8: var(--dodger-blue);
$font-family_1: work sans;
$background-color_1: white;
$background-color_2: #fff;
$background-color_3: var(--sky-blue);
$background-color_4: var(--ocean-blue);
$background-color_5: var(--dark-cyan);
$background-color_6: var(--dark-navy);
$background-color_7: var(--powder-blue);

.ov-comp-drop-down {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    height: 48px;
    background-color: $background-color_1;
    color: $color_1;
    border-radius: 8px;
    border: 1px solid var(--light-blue);

    font-size: 20px;
    font-family: $font-family_1;
    text-transform: capitalize;
    transition: 0.5s ease-in-out;

    cursor: pointer;


    >.selected {
        padding: 0 15px;
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
    }

    &.open {
        border-radius: 8px 8px 0px 0px;

        >.menu {
            display: block;
        }
    }

    >.menu {
        display: none;
        width: 100%;

        >.options {
            position: absolute;
            max-height: 200px;
            width: 100%;
            overflow-y: auto;
            background-color: $background-color_2;
            border: 1px solid #ccc;
            border-top: none;
            border-radius: 0 0 8px 8px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

            .option {
                padding: 15px 20px;
                cursor: pointer;
                border-bottom: 1px solid var(--light-azure);
                color: $color_1;
                font-size: 20px;
                transition: 0.3s ease;
                text-transform: capitalize;

                &:hover {
                    background-color: $background-color_3;
                    color: $color_2;
                }
            }
        }

        >.search {
            width: 100%;
            padding: 10px 20px;
            cursor: text;
            border-bottom: 1px solid var(--light-azure);
            color: $color_1;
            font-size: 20px;
            transition: 0.3s ease;
            position: sticky;
            top: 0px;
        }
    }
}



.big-drop-down {
    width: 250px;

    &:hover {
        background-color: $background-color_7;
        border: 1px solid var(--dodger-blue);
    }
}

.ovoky-choice-drop-down {
    width: 100%;
    height: 55px;
    color: $color_3;
    background-color: $background-color_4;
    box-shadow: 0px 2px 2px 0px #00000040;
    border: 2px solid transparent;
}

.options-ovoky-choice-drop-down {
    width: 100%;
    height: 300px;
    background-color: $background-color_5;
    border: 2px solid transparent;
    z-index: 1;
    position: relative;

    >.autocomplete-input {
        display: none;
    }

    >.option {
        height: 55px;
        color: $color_4;
        border-bottom: 0px;

        &:last-child {
            border-bottom: 0px;
        }

        &:hover {
            background-color: $background-color_6;
        }
    }
}

.options-big-drop-down {
    width: 250px;
    z-index: 1;
    position: absolute;
    top: 72px;

    >.autocomplete-input {}
}

.small-drop-down {
    width: 250px;

    &:hover {
        background-color: $background-color_7;
        border: 1px solid var(--dodger-blue);
    }
}

.options-small-drop-down {
    >.autocomplete-input {
        display: none;
    }

    width: 250px;
}

.drop-down-name {
    color: $color_5;
    font-size: 16px;
    font-family: $font-family_1;
    margin: 0;
    text-align: left;
}

.powder-blue-bg {
    background: #F0FAFF;
    width: 560px;
    border: 0px;
    border-right: 2px solid var(--sky-blue);
    border-left: 2px solid var(--sky-blue);
    height: 60px;
    border-radius: 8px 0px 0px 0px;
    color: $color_6;

    &:hover {
        background: var(--powder-blue);
    }
}

.options-powder-blue-bg-drop-down {
    width: 560px;
    margin-top: 2px;
    text-align: left;
    min-height: 300px;

    >.option {
        padding: 20px;
        color: $color_2;
        box-shadow: inset 0px 0px 0px 1px var(--sky-blue);
        transition: 0.3s ease;

        &:hover {
            background-color: $background-color_7;
        }
    }
}

// .drop-down-calendar {
//     width: 150px;
//     color: $color_1;
//     font-size: 20px;
//     cursor: pointer;
//     padding: 11px 20px;
//     background-color: $background-color_7;
//     border: 2px solid transparent;
//     font-weight: 400;
//     line-height: 1.5;
//     justify-content: space-around;
//     transition: 0.3s ease;

//     &:hover {
//         color: $color_7;
//         border: 2px solid var(--dodger-blue);
//         background-color: $background-color_3;
//     }
// }

// .options-drop-down-calendar {
//     >input {
//         display: none;
//     }

//     width: 150px;
//     color: $color_8;
//     font-size: 17px;
//     font-weight: 400;
//     line-height: 1.5;
// }

// .drop-down-calendar.active {
//     color: $color_3;
//     border: 2px solid transparent;
//     background-color: $background-color_4;
// }

// @media (min-width : 0px) and (max-width : 470px) {
//     .powder-blue-bg {
//         width: 100%;
//         height: 75px;
//         border: 2px solid var(--sky-blue);
//         border-radius: 8px 8px 0px 0px;
//     }

//     .options-powder-blue-bg-drop-down {
//         width: 100%;
//         margin-top: 0px;
//     }
// }

// @media (min-width : 470px) and (max-width : 1300px) {
//     .powder-blue-bg {
//         width: 100%;
//         height: 75px;
//         box-shadow: inset 0px 0px 0px 2px var(--sky-blue);
//         border-radius: 8px 8px 0px 0px;
//         border: 0px;
//     }

//     .options-powder-blue-bg-drop-down {
//         width: 100%;
//         margin-top: 0px;
//     }
// }