.pricing {
    padding: 20px 00px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.pricing-header {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 0px 200px ;
}

.pricing-header>.pricing-title {
    color: var(--dark-cyan);
    text-align: center;
}

.pricing-header>.paragraph {
    border-radius: 8px;
    background-color: var(--light-aqua);
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    color: var(--dark-green);
}

.pricing-header>.paragraph>p {
    text-align: left;
    max-width: 1045px;
    font-size: 20px;
    font-family: work sans;
}

.pricing-content {
    padding: 0px 200px;
}

.pricing-content>.pricing-drop-down {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.pricing-content>.plans {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
    row-gap: 16px;
    padding-top: 40px;
    /* padding-bottom: 50px; */
}

@media (min-width : 0px) and (max-width : 470px) {
    .pricing-header {
        gap: 45px;
        padding: 0px 20px 20px 20px;
    }
    .pricing-header>.pricing-title{
        font-size: 24px;
        text-align: left;
    }
    .pricing-header>.paragraph {
        height: 100%;
        flex-direction: row-reverse;
        padding: 30px 30px 30px 10px;
        gap: 15px;
        align-items: flex-start;
    }
    .pricing-header>.paragraph>p{
        text-align: justify;
        font-size: 16px;
    }
    .pricing-content {
        padding: 0px 20px 0px 20px;
    }
    .pricing-content>.plans {
        display: flex;
        flex-direction: column;
        gap: 45px;
    }
    .pricing-content>.pricing-drop-down {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media (min-width : 470px) and (max-width : 1300px) {
    .pricing-header {
        gap: 45px;
        padding: 0px 70px 0px 70px;
    }
    .pricing-header>.pricing-title{
        font-size: 46px;
        text-align: left;
    }
    .pricing-header>.paragraph {
        height: 100%;
        padding: 30px 30px 30px 10px;
        gap: 15px;
        align-items: flex-start;
    }
    .pricing-header>.paragraph>p{
        text-align: justify;
        font-size: 20px;
    }
    .pricing-content {
        padding: 0px;
    }
    .pricing-content>.plans {
        display: flex;
        flex-direction: column;
        gap: 45px;
        padding: 40px 70px 0px 70px;
    }
    .pricing-content>.pricing-drop-down {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 70px;
        flex-wrap: wrap;
    }
}