.author {
    padding: 30px 20px;
    border: 1px solid var(--jungle-green);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background: linear-gradient(150deg, var(--light-aqua) -30%, #ffffff 40%);
    transition: 0.3s ease-in-out;
    max-width: 480px;
}
.author:hover{
    border: 1px solid var(--green-cyan);
    background: linear-gradient(150deg, var(--jungle-green) -30%, #ffffff 40%);
    box-shadow: var(--shadow-gray);
}
/* .author:hover + .author>.head>div:first-child>a>div>p{
    color: var(--dark-green);
} */
.author>.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.author>.head>div:first-child>a {
    display: flex;
    gap: 8px;
    align-items: flex-start;
}
.author>.head>div:first-child>a>img{
    width: 75px;
    height: 75px;
}
.author>.head>div:first-child>a>div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

.author>.head>div:first-child>a>div>h3 {
    font-size: 17px;
    font-weight: 400;
    color: var(--g-10);
}

.author>.head>div:first-child>a>div>p {
    font-size: 14px;
    font-weight: 400;
    color: var(--g-30);
}

.author>.head>div:last-child>.btn {
    background-color: var(--sky-blue);
    color: var(--ocean-blue);
    height: 46px;
    padding: 10px 20px;
    border: 1px solid transparent;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    transition: 0.3s ease;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.author>.head>div:last-child>.btn:hover {
    color: var(--powder-blue);
    background-color: var(--dodger-blue);
}

.author>.head>div:last-child>.button-active {
    background-color: var(--dark-cyan);
    color: var(--powder-blue);
}

.author>.content>p {
    text-align: left;
    color: var(--hunter-green);
    font-size: 17px;
    line-height: 1.5;
    font-weight: 400;
}

.author>.foot {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.author>.foot>p {
    background-color: var(--light-aqua);
    padding: 11px 12px;
    color: var(--dark-green);
    border-radius: 4px;
    transition: 0.3s ease;
    border: 1px solid transparent;
}
.author>.foot>p:hover{
    background-color: var(--mint-green);
    border: 1px solid var(--green-cyan);
}


@media (min-width : 470px ) and (max-width : 1300px) {
    /* .author {
        max-width: 100%;
    } */
}