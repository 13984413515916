.hero-section {
    display: flex;
    justify-content: space-between;
    height: 700px;
    gap: 60px;
    max-width: 100%;
    position: relative;
}

.bg-repeat {
    background: url(/src/assets/blue-unit.svg) repeat;
    width: 100%;
    height: 700px;
    z-index: -1;
    position: absolute;
    background-size: 40px;
}

.bg-linear {
    background: linear-gradient(100deg, #E8F8FF 30%, #D7F3FF 62%);
    width: 100%;
    z-index: -2;
    position: absolute;
    height: 700px;
}

@media (min-width : 0px) and (max-width : 470px) {
    .hero-section {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        max-width: 100%;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media (min-width : 470px) and (max-width : 1300px) {

    .hero-section {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        height: 100%;
    }

    .bg-repeat {
        min-height: 100%;
    }

    .bg-linear {
        min-height: 100%;
    }
}