.main-product{
    display: flex;
    flex-direction: column;
    gap: 60px;
}
.main-product>.card-buttons{
    display: flex;
    justify-content: flex-start;
}
.show{
    display: flex;
    height: 650px;
    /* padding-top: 30px; */
}
.hide{
    display: none;
}

@media (min-width : 0px) and (max-width : 470px) {
    .main-product{
        width: 100%;
    }
    .main-product>.card-buttons{
        align-items: center;
    }
    .show{
        height: 100%;
    }
}
@media (min-width : 470px) and (max-width : 1300px) {
    .main-product{
        width: 100%;
    }
    .main-product>.card-buttons{
        align-items: center;
    }
    .show{
        height: 100%;
    }
}