.footer {
    position: relative;
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
}

.bg-repeat-footer {
    background: url(/src/assets/green-unit.svg) repeat;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    position: absolute;
    background-size: 40px;
}

.bg-linear-footer {
    background: linear-gradient(-100deg, #99FFDD 30%, #ffffff 150%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -2;
    position: absolute;
}

.footer>.first-div {
    max-width: var(--section-container-max-width);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.footer>.second-div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: var(--section-container-max-width);
    width: 100%;
}

.footer>div>.list>ul {
    list-style: none;
    display: flex;
    gap: 20px;
}

.footer>div>.list>ul>li>a {
    color: var(--dark-green);
    font-size: 20px;
}

.footer>div>p {
    color: #2E2E2E;
}



@media (min-width : 0px) and (max-width : 470px) {
    .footer>.first-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        align-items: flex-start;
        width: 100%;
    }
    .footer>.first-div>.list>ul {
        flex-direction: column;
    }
    .footer>.second-div {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        gap: 30px;
        align-items: flex-start;
        width: 100%;
    }
    .footer>.second-div>p{
        font-size: 14px;
    }
}
@media (min-width : 470px) and (max-width : 1300px) {
    .footer {
        position: relative;
        padding: 60px 70px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
    }
    .footer>div>.list>ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
    .footer>.first-div {
        gap: 20px;
    }
}