.selection-bar {
    height: 55px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 5px 20px;
    justify-content: center;
}

.selection-bar>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: var(--g-30);
    font-size: 17px;
    padding: 8px 20px;
    transition: 0.3s ease;
    border-radius: 8px;
}

.selection-bar>.selection-bar-line {
    padding: 0px;
    height: 33px;
    width: 1px;
    background-color: var(--silver-gray);
}

.selection-bar>div:first-child:hover {
    color: var(--ocean-blue);
    background-color: var(--powder-blue);
}
.selection-bar>div:first-child.active{
    color: var(--powder-blue);
    background-color: var(--dark-cyan);
    box-shadow: 0px 3px 5px 0px gray;
}



@media (min-width : 0px ) and (max-width : 470px) {
    .selection-bar {
        width: 100%;
        padding: 0px;
        flex-direction: column;
        gap: 0px;
    }
    .selection-bar:first-child>div:first-child {
       width: 100%;
       height: 55px;
       border-radius: 8px 8px 0px 0px;
    }
    .selection-bar>div:first-child {
       width: 100%;
       height: 55px;
       border-radius: 0px;
    }
    .selection-bar:last-child>div:first-child {
       width: 100%;
       height: 55px;
       border-radius: 0px 0px 8px 8px;
    }
    .selection-bar>div:first-child.active{
        box-shadow: 0px 0px 0px 0px gray;
    }
    .selection-bar>.selection-bar-line {
        padding: 0px;
        height: 2px;
        width: 100%;
        background-color: var(--silver-gray);
    }
    .selection-bar:last-child>.selection-bar-line:last-child{
        display: none;
    }
}

@media (min-width : 470px) and (max-width : 1300px){
    .selection-bar{
        padding: 0px;
        gap: 0px;
    }
    .selection-bar>div:first-child{
        height: 100%;
        width: 100%;
        border-radius: 0px;
        padding: 8px 42px;
    }
    .selection-bar:first-child>div:first-child{
        border-radius: 8px 0px 0px 8px;
    }
    .selection-bar>div:first-child.active{
        box-shadow: 0px 0px 0px 0px gray;
    }
}