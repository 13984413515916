*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    border: none;
    text-indent: 0px;
    border-radius: 0px;
    outline: none;
    text-decoration: none;
    font-family: work sans;
}
