.accordion {
    /* height: 60px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.accordion>.bars-icon {
    display: flex;
    flex-direction: column;
    width: 20px;
    height: 20px;
    position: relative;
}

.accordion>.bars-icon>.bar-icon {
    width: 100%;
    height: 3px;
    background-color: var(--light-blue);
    position: absolute;
    transition: 0.3s ease-in-out;
}
.accordion>.bars-icon>.bar-icon:nth-child(1){
    top: 50%;
}
.accordion>.bars-icon>.bar-icon:nth-child(2){
    transform: rotateZ(90deg);
    top: 50%;
}
.accordion>.bars-icon.active>.bar-icon:nth-child(1){
    top: 50%;
}
.accordion>.bars-icon.active>.bar-icon:nth-child(2){
    transform: rotateZ(0deg);
    top: 50%;
}

.full-bgColor-theme>h3 {
    color: var(--ocean-blue);
    font-size: 25px;
    font-weight: 400;
}

.full-bgColor-theme>span {
    color: var(--ocean-blue);
    font-size: 25px;
    font-weight: 400;
}

.full-bgColor-theme {
    background: linear-gradient(90deg, #00000000 0%, #99DDFF80 50%, #00000000 100%);
    width: 100%;
    padding: 15px 0;
    transition: 0.3s ease;
}

.full-bgColor-theme:hover {
    background: linear-gradient(90deg, var(--sky-blue) 50%, var(--sky-blue) 100%);
}

.full-bgColor-theme.active {
    background: linear-gradient(90deg, var(--sky-blue) 50%, var(--sky-blue) 100%);
}

.gradient-bg-theme {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(100deg, var(--sky-blue) -80%, #fff 40%);
    border: 1px solid var(--sky-blue);
    border-radius: 8px;
    padding: 20px;
    /* max-width: 800px; */
    margin-top: 16px;
    transition: 0.3s ease;
}

.gradient-bg-theme:hover {
    background: linear-gradient(40deg, #35BCFF -80%, #ffffff 70%);
}

.gradient-bg-theme.open {
    border-radius: 8px 8px 0px 0px;
}

.gradient-bg-theme>h3 {
    color: var(--dark-blue);
    font-size: 20px;
    line-height: 1.5;
    text-align: left;
    font-weight: 400;
}

.gradient-bg-theme>span {
    color: var(--light-blue);
    font-size: 25px;
    font-weight: 500;
}


@media (min-width : 0px) and (max-width : 470px) {
    .full-bgColor-theme {
        width: 335px;
        height: 75px;
        border: 2px solid var(--g-90);
        border-radius: 8px;
        background: white;
        align-self: center;
    }

    .full-bgColor-theme>h3 {
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        transition: 0.3s ease-in-out;
        color: var(--ocean-blue);
    }

    .full-bgColor-theme>span {
        font-size: 14px;
    }

    .full-bgColor-theme:hover {
        background: white;
    }

    .full-bgColor-theme.active {
        background: white;
    }

    .gradient-bg-theme {
        padding: 15px;
        margin-top: 15px;
    }

    .gradient-bg-theme>h3 {
        font-size: 14px;
    }
}