.hero-section>.hero-container {
  width: 50%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0px 60px 200px;
}

.hero-container>.content-container {
  display: flex;
}

.hero-container>.content-container>.text {
  padding-bottom: 60px;
  /* display: none; */
  opacity: 0;
  transition: transform 0.5s ease;
}

.hero-container>.content-container>.text.active {
  opacity: 1;
  transform: translateX(0);
  display: block;
  transition: opacity 0.5s ease, transform 0.5s ease
}

.hero-container>.content-container>.text>.header {
  padding-bottom: 30px;
  display: block;
}

.hero-container>.content-container>.text>.header>h1 {
  font-size: 80px;
  color: #00A6F9;
  margin: 0px;
}

.hero-container>.content-container>.text>.header>h2 {
  font-size: 64px;
  color: var(--dark-blue);
}

.hero-container>.content-container>.text>p {
  color: #0A577E;
  font-size: 20px;
  font-family: work sans;
}

.hero-container>.content-container>.div-button {
  width: 200px;
}

.carousel {
  width: 50%;
  height: 100%;
  overflow: hidden;
  padding: 60px 200px 60px 0px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.carousel-container {
  display: flex;
  height: 500px;
  position: relative;
  width: 730px;
  transition: transform 0.5s ease;
}

.carousel-slide {
  width: 100%;
  height: 500px;
  flex: 0 0 auto;
  /* display: none; */
  /* transition: transform 0.5s ease; */
  opacity: 0;
  background: linear-gradient(0deg, #80D5FF 0%, #E8F7FF 100%);
  border-radius: 5px;
  transition: opacity 0.5s ease, transform 0.5s ease
}

.carousel-slide>.icon {
  position: absolute;
  left: -50px;
}

.carousel-slide img {
  object-fit: cover;
  border-radius: 5px;
  z-index: 1;
  height: 500px;
}

.carousel-slide.active {
  opacity: 1;

  display: flex;
  justify-content: center;
}

/* .carousel-slide:nth-child(2).active {
  opacity: 1;
  transform: translateX(-730px);
  display: flex;
  justify-content: center;
}
.carousel-slide:nth-child(3).active {
  opacity: 1;
  transform: translateX(-1460px);
  display: flex;
  justify-content: center;
} */

.indicators {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 40px; */
}

.indicator {
  width: 60px;
  height: 16px;
  background-color: var(--sky-blue);
  border: 2px solid transparent;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.3s ease;
}

.indicator:hover {
  background-color: var(--light-azure);
  border: 2px solid var(--dodger-blue);
}

.indicator.active {
  background-color: var(--dodger-blue);
}

@media (min-width : 0px) and (max-width : 470px) {
  .hero-section>.hero-container {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px 45px 20px;
  }

  .hero-container>.content-container {
    display: flex;

  }

  .hero-container>.content-container>.text {
    padding-bottom: 45px;
    /* display: none; */
    transition: transform 0.5s ease;
    opacity: 0;
  }

  .hero-container>.content-container>.text>.header>h1 {
    font-size: 46px;
  }

  .hero-container>.content-container>.text>.header>h2 {
    font-size: 40px;
  }

  .hero-container>.content-container>.text>p {
    font-size: 16px;
    min-height: 100px;
  }

  .hero-container>.content-container>.div-button {
    min-width: 300px;
  }

  .hero-container>.content-container>.div-button>a {
    width: 100%;
  }

  .hero-container>.content-container>.div-button>a>button {
    width: 100%;
  }

  .carousel {
    width: 100%;
    height: 267px;
    overflow: hidden;
    padding: 40px 20px 0px 20px;
  }

  .carousel-container {
    height: 100%;
    display: flex;
    position: relative;
    width: 100%;
    transition: transform 0.5s ease;
  }

  .carousel-slide {
    width: 100%;
    height: 100%;
  }

  .carousel-slide>img {
    object-fit: cover;
    height: 100%;
  }

  .indicators {
    display: none;
  }
}

@media (min-width : 470px) and (max-width : 1300px) {
  .hero-section{
    gap: 45px;
  }
  .hero-section>.hero-container {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 00px 80px 40px 80px;
  }

  .hero-container>.content-container>.text {
    padding-bottom: 45px;
    /* display: none; */
    opacity: 0px;
    transition: transform 0.5s ease;
  }

  .hero-container>.content-container>.text>.header>h1 {
    font-size: 80px;
  }

  .hero-container>.content-container>.text>.header>h2 {
    font-size: 64px;
  }

  .hero-container>.content-container>.text>p {
    font-size: 16px;
    min-height: 80px;
  }

  .hero-container>.content-container>.div-button {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .hero-container>.content-container>.div-button>a {
    width: 335px;
  }

  .hero-container>.div-button>a>button {
    width: 100%;
  }

  .carousel {
    width: 100%;
    height: 100%;
    overflow: visible;
    padding: 70px 80px 0px 80px;
    gap: 45px;
  }

  .carousel-container {
    height: 420px;
    width: 100%;
  }

  .carousel-slide {
    width: 100%;
    height: 100%;
  }

  .carousel-slide>img {
    object-fit: cover;
    height: 100%;
  }

  .indicators {
    z-index: 2;
  }
}