.search-bar {
    display: flex;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    height: 100%;
    align-items: center;
    padding: 0px 10px;
    gap: 10px;
    border: 1px solid var(--light-blue);
    position: relative;
}

.search-bar>input {
    width: 694px;
    background-color: transparent;
    font-size: 20px;
}

.search-bar>button {
    background-color: transparent;
    cursor: pointer;
    width: 36px;
    height: 37px;
    display: flex;
    align-items: center;
}

.search-bar>.search-icon {
    color: var(--azure);
    /* border-left: 1px solid var(--silver-gray); */
    padding: 5px 6px;
    border-radius: 4px;
    transition: 0.3s ease;
}

.search-bar>.search-icon:hover {
    color: var(--azure);
    background-color: var(--powder-blue);
}

.search-bar>.search-icon.active {
    color: var(--dark-cyan);
    background-color: var(--sky-blue);
}

.search-bar>.setting-icon {
    color: var(--g-30);
    padding: 5px 6px;
    border-radius: 4px;
}

.search-bar>.setting-icon:hover {
    color: var(--g-20);
    background-color: var(--g-90);
}

.search-bar>.setting-icon.active {
    color: var(--aquamarine);
    background-color: var(--ocean-blue);
}

.search-bar:focus-within {
    border: 1px solid var(--light-azure);
}

.search-bar-phone {
    display: none;
}


@media (min-width : 0px) and (max-width : 470px) {
    .search-bar {
        padding: 0px 0px 0px 10px;
        display: none;
    }

    .search-bar>input {
        width: 100%;
        padding-left: 10px;
    }

    .search-bar-phone {
        display: flex;
        width: 100%;
        background-color: white;
        border-radius: 8px;
        height: 53px;
        align-items: center;
        padding: 0px 10px;
        gap: 10px;
        border: 1px solid var(--light-blue);
        position: relative;
    }

    .search-bar-phone>input {
        width: 100%;
        background-color: transparent;
        font-size: 20px;
    }

    .search-bar-phone>button {
        background-color: transparent;
        cursor: pointer;
        width: 36px;
        height: 37px;
        display: flex;
        align-items: center;
    }

    .search-bar-phone>.search-icon {
        color: var(--azure);
        /* border-left: 1px solid var(--silver-gray); */
        padding: 5px 6px;
        border-radius: 4px;
        transition: 0.3s ease;
    }

    .search-bar-phone>.search-icon:hover {
        color: var(--azure);
        background-color: var(--powder-blue);
    }

    .search-bar-phone>.search-icon.active {
        color: var(--dark-cyan);
        background-color: var(--sky-blue);
    }

    .search-bar-phone>.setting-icon {
        color: var(--g-30);
        padding: 5px 6px;
        border-radius: 4px;
    }

    .search-bar-phone>.setting-icon:hover {
        color: var(--g-20);
        background-color: var(--g-90);
    }

    .search-bar-phone>.setting-icon.active {
        color: var(--aquamarine);
        background-color: var(--ocean-blue);
    }

    .search-bar-phone:focus-within {
        border: 1px solid var(--light-azure);
    }
}

@media (min-width : 470px) and (max-width : 1300px){
    .search-bar>input {
        width: 100%;
        padding-left: 20px;
    }
    .search-bar{
        justify-content: space-between;
        padding: 0px;
        padding-right: 10px;
    }
}