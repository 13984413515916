/* style general for the button */
.button {
    border: none;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 8px;
}

/* style for blue-bg button */

.ovoky-comp-button-blue-bg {
    background-color: var(--dodger-blue);
    height: 60px;
    width: 280px;
    padding: 15px 30px;
    color: var(--powder-blue);
    transition: 0.3s ease;
    font-size: 20px;
    border-radius: 8px;
}

/* style for big button */
.ovoky-comp-button-blue-big-button {
    color: var(--powder-blue);
    background-color: var(--dodger-blue);
    width: 100%;
    height: 56px;
    transition: 0.3s ease;
    font-size: 20px;
    font-weight: 600;
    border-radius: 8px;
}

/* style for small button */
.ovoky-comp-button-small-button {
    width: 120px;
    height: 56px;
    color: var(--powder-blue);
    background-color: var(--dodger-blue);
    font-size: 20px;
    line-height: 1.3;
    transition: 0.3s ease;
}

/* style for big blue-bg button */
.ovoky-comp-button-big-blue-bg {
    color: var(--powder-blue);
    background-color: var(--dodger-blue);
    height: 56px;
    width: 288px;
    transition: 0.3s ease;
    font-size: 20px;
    line-height: 1.3;
}

/* style for the hover button */

.ovoky-comp-button-blue-bg:hover,
.ovoky-comp-button-big-blue-bg:hover,
.ovoky-comp-button-small-button:hover {
    background-color: var(--dark-cyan);
}

.ovoky-comp-button-blue-big-button:hover {
    filter: grayscale(40%);
}

/* style for yellow button */
.ovoky-comp-button-bg-index-0 {
    height: 60px;
    width: 300px;
    background-color: var(--y-90);
    color: var(--y-60);
    border: 2px solid transparent;
    font-size: 22px;
    transition: 0.3s ease;
    border-radius: 8px 0px 0px 8px;
}


.ovoky-comp-button-bg-index-0:hover {
    background-color: var(--y-90);
    border: 2px solid var(--y-40);
    color: var(--y-20);
    border-right: 0px;
}

.ovoky-comp-button-bg-index-0.active {
    background-color: var(--y-40);
    color: var(--y-90);
    box-shadow: -5px 5px 10px 1px rgb(0, 0, 0, 0.15)inset;
    border: 2px solid transparent;
}

.ovoky-comp-button-bg-index-0.active:hover {
    background-color: var(--y-40);
    color: var(--y-90);
    box-shadow: -5px 5px 10px 1px rgb(0, 0, 0, 0.15) inset;
    border: 2px solid transparent;
}

/* style for ocean-color button */
.ovoky-comp-button-bg-index-1 {
    height: 60px;
    width: 300px;
    background-color: var(--powder-blue);
    color: var(--light-blue);
    border: 2px solid transparent;
    font-size: 22px;
    transition: 0.3s ease;
    border-radius: 0px;
}

.ovoky-comp-button-bg-index-1:hover {
    background-color: var(--sky-blue);
    border: 2px solid var(--dodger-blue);
    border-left: 0px;
    border-right: 0px;
    color: var(--dark-cyan);
}

.ovoky-comp-button-bg-index-1.active {
    background-color: var(--ocean-blue);
    color: var(--powder-blue);
    box-shadow: 5px 5px 10px 1px rgb(0, 0, 0, 0.15) inset;
    border: 2px solid transparent;
    border-left: 0px;
    border-right: 0px;
}

.ovoky-comp-button-bg-index-1.active:hover {
    background-color: var(--ocean-blue);
    color: var(--powder-blue);
    box-shadow: 5px 5px 10px 1px rgb(0, 0, 0, 0.15) inset;
    border: 2px solid transparent;
}

/* style for green button */
.ovoky-comp-button-bg-index-2 {
    height: 60px;
    width: 300px;
    background-color: var(--light-aqua);
    color: var(--green-cyan);
    border: 2px solid transparent;
    font-size: 22px;
    transition: 0.3s ease;
    border-radius: 0 8px 8px 0;
}


.ovoky-comp-button-bg-index-2:hover {
    background-color: var(--aquamarine);
    border: 2px solid var(--dark-green);
    color: var(--dark-green);
    border-left: 0px;
}

.ovoky-comp-button-bg-index-2.active {
    background-color: var(--jungle-green);
    color: var(--light-aqua);
    box-shadow: -5px 5px 10px 1px rgb(0, 0, 0, 0.15)inset;
    border: 2px solid transparent;
}

.ovoky-comp-button-bg-index-2.active:hover {
    background-color: var(--jungle-green);
    color: var(--light-aqua);
    box-shadow: -5px 5px 10px 1px rgb(0, 0, 0, 0.15) inset;
    border: 2px solid transparent;
}




.ovoky-comp-sub-button {
    color: white;
    background-color: var(--light-azure);
    border: 2px solid transparent;
    width: 140px;
    height: 46px;
    transition: 0.3s ease;
}

.ovoky-comp-sub-button:hover {
    color: white;
    background-color: var(--light-blue);
    border: 2px solid var(--dodger-blue);
}

.ovoky-comp-sub-button:active {
    color: white;
    background-color: var(--dodger-blue);
    border: 2px solid var(--dodger-blue);
}

@media (min-width : 0px) and (max-width : 470px) {

    .ovoky-comp-button-bg-index-0,
    .ovoky-comp-button-bg-index-1,
    .ovoky-comp-button-bg-index-2 {
        font-size: 14px;
        height: 52px;
    }
}
@media (min-width : 470px) and (max-width : 1300px) {

    .ovoky-comp-button-bg-index-0,
    .ovoky-comp-button-bg-index-1,
    .ovoky-comp-button-bg-index-2 {
        font-size: 14px;
        height: 68px;
    }
}