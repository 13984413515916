.ovoky-comp-header {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    position: sticky;
    top: 90px;
    background-color: white;
    padding: 0px 200px;
    z-index: 1;
}

.ovoky-comp-header>span {
    color: var(--dark-cyan);
    font-size: 16px;
    font-family: work sans;
}

.ovoky-comp-header>div {
    background-color: var(--grey);
    border-radius: 5px;
    width: 100%;
    height: 1px;
}

@media (min-width : 0px) and (max-width : 470px) {
    .ovoky-comp-header {
        padding: 0px 20px;
        top: 60px;
    }
    .ovoky-comp-header>span {
        font-size: 14px;
    }
}
@media (min-width : 470px) and (max-width : 1300px) {
    .ovoky-comp-header {
        padding: 0px 70px;
        top: 60px;
    }
    .ovoky-comp-header>span {
        font-size: 14px;
    }
}