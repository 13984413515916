.choice {
    align-self: center;
    transform-origin: 0;
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;
}

.choice>button {
    width: 34px;
    height: 34px;
    border: none;
    border-radius: 4px;
    background-color: var(--dark-cyan);
    position: absolute;
    transition: 0.3s ease;
}

.choice>.ovoky-icon {
    top: 70px;
    left: 105px;
    transform: rotate(-40deg);
}

.choice>.article-icon {
    left: 150px;
}

.choice>.author-icon {
    top: 300px;
    left: 105px;
    transform: rotate(40deg);
}

.choice>button:hover {
    cursor: pointer;
    background-color: var(--sky-blue);
}

.choice>button.article-icon:hover svg>rect,
.choice>button.article-icon:hover svg>path {
    stroke: var(--dark-cyan);
}

.choice>button.author-icon:hover svg>rect,
.choice>button.author-icon:hover svg>path {
    stroke: var(--dark-cyan);
    fill: var(--dark-cyan);
}

.choice>button.ovoky-icon:hover svg>rect,
.choice>button.ovoky-icon:hover svg>path {
    stroke: var(--dark-cyan);
    fill: var(--dark-cyan);
}

.choice>.cercle {
    width: 200px;
    border-radius: 0px 800px 800px 0;
    border-top: 2px solid white;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    height: 400px;
    border-color: var(--powder-blue);
    background: linear-gradient(90deg, rgb(51, 187, 255, 0) 30%, var(--light-blue) 200%);
}

.box-choice {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    justify-content: center;
}

.box-choice>div {
    background-color: var(--powder-blue);
    padding: 5px 15px;
    border-radius: 8px;
    display: flex;
    gap: 10px;
    align-items: center;
    width: 188px;
    height: 46px;
    justify-content: flex-end;
}

.box-choice>div>.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box-choice>div>.buttons>button {
    background-color: transparent;
    color: var(--ocean-blue);
    font-size: 0px;
    cursor: pointer;
}

.box-choice>div>.buttons>button:disabled {
    color: gray;
    cursor: default;
}

.box-choice>div>.buttons>button>svg {
    font-size: 20px;
}

.box-choice>p {
    color: var(--light-blue);
    font-size: 14px;
    position: absolute;
    padding-left: 15px;
    white-space: nowrap;
    cursor: pointer;
    transform-origin: -220px;
    transition-property: transform opacity filter;
    transition-duration: 300ms;
    transition-timing-function: ease-out;
    /* display: none; */
}

.box-choice>p.chosen {
    color: var(--ocean-blue);
    font-size: 20px;
    font-weight: 400;
    display: block;
}
.drop-down-container{
    display: none;
}

@media (min-width : 0px) and (max-width : 470px) {
    .choice {
        width: 100%;
        display: none;
    }

    .choice>.cercle {
        display: none;
    }

    .drop-down-container{
        display: block;
    }
}

@media (min-width : 470px) and (max-width : 1300px) {
    .choice {
        position: absolute;
        top: 185px;
        right: 75px;
    }
}