.App {
  /* text-align: center; */
  position: relative;
  overflow: clip;
}
/* .App.disabled{
  background: #dddddd;
} */
.overlay-App {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-App-phone{
  position: fixed;
  /* filter: blur(500px); */
  filter: blur(8px);
  -webkit-filter: blur(8px);
  opacity: 0.8;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #006699 0%, #E5F6FF 100%);
  /* box-shadow: 0px 100px 100px 1px #006699 inset; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay-App-phone-blog{
  position: fixed;
  /* filter: blur(500px); */
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
  opacity: 0.8;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #002233 0%, #000000 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  justify-content: center;
}
@media (min-width : 0px) and (max-width : 470px) {
  .overlay-App{
    display: none;
  }
}
@media (min-width : 470px) and (max-width : 1300px) {
  .container {
    justify-content: center;
  }
  .overlay-App{
    display: none;
  }
  .container>.author:last-child{
    display: none;
  }
}