$color_1: var(--green-cyan);
$color_2: #FF002E;
$color_3: var(--dark-navy);
$color_4: var(--dark-cyan);
$color_5: var(--g-60);
$background-color_1: var(--silver-gray);
$background-color_2: white;

.form-container {
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	>.form-content {
		display: flex;
		gap: 50px;
		align-items: flex-start;
		// padding: 40px 200px 0px 200px;
		position: relative;
		// max-width: 140px;
    width: 100%;
    // margin: 0 auto;
    padding: 40px 200px;

		>.messages {
			width: 50%;

			>div {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 20px;

				>svg {
					width: 70px;
					height: 70px;
				}
			}

			>.success {
				>.success-message {
					font-size: 26px;
					align-self: center;
					color: $color_1;
				}

				>a {
					>button {
						width: 150px;
					}
				}
			}

			>.error {
				>.error-message {
					font-size: 26px;
					align-self: center;
					color: $color_2;
				}
			}
		}

		>.line {
			display: flex;
			width: 1px;
			min-width: 1px;
			background-color: $background-color_1;
			height: 500px;
		}

		>.content {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 40px;
			width: 50%;

			// >div {
			// 	color: $color_3;
			// 	text-align: left;
			// }
		}

		>.form {
			width: 55%;

			>form {
				display: flex;
				gap: 30px;
				flex-wrap: wrap;

				>div {

					// &:first-child {
					// 	width: 50%;
					// }

					display: flex;
					flex-direction: column;
					gap: 10px;
					width: 100%;

					>#message {
						&:focus {
							border: 1px solid var(--light-blue);
						}

						&:hover {
							border: 1px solid var(--light-blue);
						}

						height: 100%;
						min-width: 360px;
						display: flex;
						align-items: flex-start;
						background-color: $background-color_2;
						border: 1px solid var(--silver-gray);
						border-radius: 6px;
						padding: 10px 10px;
						font-size: 20px;
						transition: 0.3s ease;

						&::placeholder {
							font-size: 17px;
							color: $color_5;
						}
					}
				}
			}
		}

		>.icon-form {
			position: absolute;
			width: 226px;
			bottom: -115px;
			z-index: -3;
			left: 0;
			right: 0;
			transform: rotate(10deg);
		}
	}
}

// @media (min-width : 0px) and (max-width : 470px) {
// 	// .form-container {
// 	// 	border: 2px solid magenta;
// 	// }
// 	.form-container {
// 		>.form-content {
// 			display: flex;
// 			gap: 10px;
// 			flex-direction: column;
// 			padding: 45px 20px;
// 			gap: 45px;

// 			>.line {
// 				display: none;
// 			}

// 			>div {
// 				>div {
// 					font-size: 24px;
// 				}

// 				display: flex;
// 				flex-direction: column;
// 				gap: 45px;
// 				width: 100%;

// 				>a {
// 					width: 100%;

// 					>button {
// 						width: 100%;
// 					}
// 				}
// 			}

// 			>.form {
// 				width: 100%;

// 				>form {
// 					width: 100%;

// 					>div {
// 						width: 100%;

// 						&:first-child {
// 							width: 100%;
// 						}

// 						>label {
// 							font-size: 16px;
// 						}

// 						>input {
// 							width: 100%;
// 						}

// 						>textarea {
// 							width: 100%;
// 							min-height: 150px;
// 						}

// 						// >#message {
// 						// 	min-width: 100%;
// 						// }
// 					}
// 				}

// 				>a {
// 					width: 112px;
// 				}
// 			}

// 			>.icon-form {
// 				position: absolute;
// 				width: 150px;
// 				bottom: -95px;
// 				z-index: -3;
// 				left: auto;
// 				right: -10px;
// 				transform: rotateX(0deg) rotateY(180deg) rotateZ(15deg);
// 			}
// 		}
// 	}
// }

@media (min-width : 0px) and (max-width : 1000px) {
	.form-container {
		gap: 0px;

		>.form-content {
			display: flex;
			gap: 10px;
			flex-direction: column;
			padding: 40px 70px;
			gap: 60px;

			>.line {
				display: none;
			}

			>div {
				>div {
					font-size: 46px;
				}

				display: flex;
				flex-direction: column;
				gap: 45px;
				width: 100%;

				>a {
					width: 288px;
					height: 56px;

					>button {
						width: 100%;
					}
				}
			}

			>.form {
				width: 100%;

				>form {
					width: 100%;

					>div {
						&:first-child {
							width: 100%;
						}

						width: 100%;

						>label {
							font-size: 20px;
						}

						>input {
							width: 100%;
						}

						>#message {
							min-width: 100%;
							min-height: 200px;
						}
					}
				}

				>a {
					width: 112px;
				}
			}

			>.icon-form {
				position: absolute;
				width: 170px;
				bottom: -110px;
				z-index: -3;
				right: -10px !important;
				left: auto;
				transform: rotateX(0deg) rotateY(180deg) rotateZ(15deg);
			}
		}
	}
}

.ov-comp-form-input {
	>label {
		text-align: left;
		color: $color_4;
	}

	>.input {
		display: flex;
		gap: 4px;

		>input, >textarea {
			width: 100%;
			height: 48px;
			background-color: $background-color_2;
			border: 1px solid var(--silver-gray);
			border-radius: 6px;
			padding: 0px 10px;
			font-size: 20px;
			transition: 0.3s ease;

			&:focus {
				border: 1px solid var(--light-blue);
			}

			&:hover {
				border: 1px solid var(--light-blue);
			}
		}

		>textarea {
			min-height: 150px;
			padding: 10px;
		}

		>.phone-prefix {
			width: 130px;
			flex-shrink: 0;
		}
	}
}

.ov-comp-form-icon-select {
	display: flex;
	flex-direction: column;
	height: 48px;
	width: 100%;
	border: 1px solid var(--silver-gray);
	border-radius: 6px;
	gap: 4px;

	>.selected {
		display: flex;
		align-items: center;
		gap: 8px;
		height: 100%;
		padding: 0 8px;
		flex-shrink: 0;
		background-color: transparent;
		cursor: pointer;

		>.icon {
			height: 32px;
			border: 1px solid var(--silver-gray);

			>* {
				height: 100%;
			}
		}
	}

	>.options {
		display: none;
		flex-direction: column;
		max-height: 400px;
		height: calc(48px * 4);
		overflow: auto;
		flex-shrink: 0;
		z-index: 1;
		background-color: white;
		border: 1px solid var(--light-blue);
		border-radius: 6px;

		>.option {
			display: flex;
			align-items: center;
			gap: 8px;
			height: 48px;
			width: 100%;
			flex-shrink: 0;
			border-bottom: 1px solid $background-color_1;
			padding: 0 8px;
			cursor: pointer;

			&:hover {
				background-color: var(--powder-blue);
			}

			>.icon {
				height: 32px;
				border: 1px solid var(--silver-gray);

				>* {
					height: 100%;
				}
			}
		}
	}

	&:focus-within, &:hover {
		border: 1px solid var(--light-blue);
	}

	&.open {
		border: 1px solid var(--light-blue);

		>.options {
			display: flex;
		}
	}
}