:root {
    /* degrees blue */
    --dark-navy: #002233;
    --dark-blue: #031C28;
    --light-blue: #33BBFF;
    --electric-blue: #00AAFF;
    --dodger-blue: #0088CC;
    --deep-sky-blue: #2698D1;
    --sky-blue: #99DDFF;
    --ocean-blue: #006699;
    --powder-blue: #CCEEFF;
    --dark-cyan: #004466;
    --azure: #00AAFF;
    --light-azure: #66CCFF;
    --medium-blue : #497991;
    --b-95 : #E5F6FF;


    /* degrees green */
    --green-cyan: #00CC88;
    --dark-green: #006644;
    --hunter-green: #003322;
    --light-aqua: #CCFFEE;
    --mint-green: #99FFDD;
    --turquoise: #33FFBB;
    --light-turquoise: #ADEBD6;
    --electric-green: #00FFAA;
    --aquamarine: #66FFCC;
    --jungle-green: #009966;
    --g-95 : #E5FFF6;

    /* degrees gray */
    --silver-gray: #D9D9D9;
    --light-gray: #C7C6C6;
    --grey: #EAEAEA;
    --g-10: #1A1A1A;
    --g-20: #333333;
    --g-30: #4D4D4D;
    --g-40: #666666;
    --g-50: #808080;
    --g-60: #999999;
    --g-70: #B3B3B3;
    --g-80: #CCCCCC;
    --g-90: #E6E6E6;

    /* red */
    --red-like: #C10023;

    /* degrees yellow */
    --y-95 : #FFF6E5;
    --y-90 : #FFEECC;
    --y-80 : #FFDD99;
    --y-60 : #FFBB33;
    --y-50 : #FFAA00;
    --y-40 : #CC8800;
    --y-20 : #664400;



    /* violet degrees */
    --dark-violet : #5C1F7A;
    --p-20 : #3F0F57;
    --p-30: #5E1782;
    --p-40: #7E1FAD;
    --p-70 : #C47DE8;
    --p-90 : #EBD4F7;

    /* shadow */
    --shadow-gray: 0 4px 4px 0 #00000040;

    /* sizes */
    --section-container-max-width: 1500px;
    
}