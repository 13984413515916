.blog>.first-div {
    /* background: url(../../public/images/bgImg.png) no-repeat; */
    width: 100%;
    min-height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.blog>.first-div>h1 {
    font-size: 80px;
    color: white;
    text-align: center;
    padding-bottom: 20px;
}

.selection-header>.selection-div {
    display: flex;
    border-radius: 8px;
    gap: 20px;
    height: 55px;
}

.selection-header>.selection-div>.selection-bar:last-child>div:last-child {
    display: none;
}

.selection-header {
    display: flex;
    gap: 60px;
    flex-direction: column;
}

.selection-header>.selection-div>.selection-button {
    height: 55px;
    width: 119px;
    border-radius: 8px;
    background-color: var(--jungle-green);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: var(--light-aqua);
    gap: 5px;
    transition: 0.3s ease;
    border: 2px solid transparent;
}

.selection-header>.selection-div>.selection-button>svg {
    font-size: 30px;
}

.selection-header>.selection-div>.selection-button:hover {
    color: var(--dark-green);
    border: 2px solid var(--green-cyan);
    background-color: var(--aquamarine);
}

.selection-header>.selection-div>.selection-button:active {
    color: var(--hunter-green);
    background-color: var(--aquamarine);
    border: 2px solid transparent;
}

.selection-box {
    background-color: white;
    border: 1px solid var(--light-blue);
    border-radius: 8px;
    flex-direction: column;
    padding: 20px;
    width: 906px;
    gap: 30px;
}

.selection-content {

    display: flex;
    /* height: 100%; */
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.selection-content>div {
    padding: 13px 20px;
    background-color: var(--powder-blue);
    border-radius: 8px;
    color: var(--dodger-blue);
    border: 2px solid transparent;
    font-size: 14px;
    font-weight: 500;
    transition: 0.3s ease;
}

.selection-content>div:hover {
    background-color: var(--sky-blue);
    color: var(--dark-cyan);
    border: 2px solid var(--dodger-blue);
}

.selection-content>div.selected {
    background-color: var(--dark-cyan);
    color: var(--powder-blue);
    border: 2px solid transparent;
}

.selection-box>.confirm-btn {
    align-self: baseline;
}

.selection-box>.confirm-btn>button {
    height: 34px;
    padding: 0px 15px;
    background-color: var(--jungle-green);
    border-radius: 4px;
    color: var(--light-aqua);
    font-size: 17px;
    border: 2px solid transparent;
    transition: 0.3s ease;
}

.selection-box>.confirm-btn>button:hover {
    color: var(--dark-green);
    border: 2px solid var(--green-cyan);
    background-color: var(--aquamarine);
}

.blog>.first-div>.selection-header>.trends>h2 {
    color: white;
    text-align: left;
    font-size: 45px;
    padding-bottom: 30px;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.blog>.first-div>.selection-header>.trends>.button-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.blog>.first-div>.selection-header>.trends>.button-list>button {
    padding: 10px 20px;
    color: var(--dark-cyan);
    background-color: var(--powder-blue);
    border: 2px solid var(--azure);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    transition: 0.3s ease all;
}

.blog>.first-div>.selection-header>.trends>.button-list>button:hover {
    background-color: var(--ocean-blue);
    border: 2px solid var(--ocean-blue);
    color: var(--powder-blue);
}

.blog>.first-div-phone {
    display: none;
}
.selection-button-tablette{
    display: none;
    height: 55px;
    width: 119px;
    border-radius: 8px;
    background-color: var(--jungle-green);
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: var(--light-aqua);
    gap: 5px;
    transition: 0.3s ease;
    border: 2px solid transparent;
}



@media (min-width : 0px) and (max-width : 470px) {
    .blog>.first-div {
        display: none;
    }
    .blog>.first-div-phone {
        padding: 20px;
        width: 100%;
        display: block;
    }

    .first-div-phone>.selection-div {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .first-div-phone>.selection-div>.selection-bar-container {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 8px;
    }
}

@media (min-width : 470px) and (max-width : 1300px) {
    .blog > .first-div{
        /* align-items: flex-start; */
        padding: 100px 70px;
    }
    .blog>.first-div>h1{
        font-size: 64px;
    } 
    .first-div>.selection-header {
        width: 100%;
        flex-wrap: wrap;
    }

    .first-div>.selection-header>.selection-div {
        width: 100%;
    }
    
    .blog>.first-div>.selection-header>.trends>.button-list {
        flex-wrap: wrap;
    }
    .selection-header>.selection-div>.selection-button{
        display: none;
    }
    .selection-button-tablette{
        display: block;
    }
    .selection-box{
        width: 100%;
    }
}