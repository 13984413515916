.articles-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
    align-items: center;
}

.articles-list>.container {
    max-width: var(--section-container-max-width);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.articles-list>.articles-head {
    max-width: var(--section-container-max-width);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px 0;
}

.articles-list>.articles-head>h1 {
    color: var(--dark-navy);
    font-size: 80px;
    line-height: 1.2;
    text-align: left;
    font-weight: 600;
}
.articles-list>.articles-head>h3{
    color: var(--light-blue);
    font-size: 25px;
    line-height: 1.2;
    text-align: left;
    font-weight: 400;
}
.articles-list>.articles-head>.articlesList-line{
    width: 100%;
    height: 2px;
    background-color: var(--silver-gray);
    display: block;
}


@media (min-width : 0px) and (max-width : 470px) {
    .articles-list>.articles-head>h1 {
        font-size: 40px;
    }
}
@media (min-width : 470px) and (max-width : 1300px) {
    .articles-list{
        padding: 0 70px;
    }
    .articles-list>.articles-head>h1 {
        font-size: 64px;
    }
    /* .articles-list > .container{
        padding: 0px 50px;
    } */
}