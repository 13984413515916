.article {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    flex-grow: 1;
    border-radius: 8px;
}

.article>.bg-img {
    width: 100%;
    overflow: hidden;
    height: 100%;
    position: relative;
    background: linear-gradient(0deg, #000000b0 -30%, #fff 100%);
    border-radius: 8px 8px 0px 0px;
}
.article>.bg-img>.article-img{
    height: 100%;
    width: 100%;
}
.article>.bg-img>.article-img>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .3s, visibility .3s ease-in;
}

.article:hover .bg-img>.article-img>img {
    transform: scale(1.1);
}

.article>.bg-img>.save-img {
    position: absolute;
    z-index: 0;
    right: 72px;
    top: 20px;
    color: var(--light-blue);
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    transition: 0.3s ease-in;
    border: 1px solid transparent;
    cursor: pointer;
}

.article>.bg-img>.link-img {
    position: absolute;
    z-index: 0;
    right: 20px;
    top: 20px;
    color: var(--g-20);
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    transition: 0.3s ease-in;
    border: 1px solid #E6EDF0;
    cursor: pointer;
}

.save {
    display: none;
}

.saved {
    background-color: var(--ocean-blue);
    color: var(--powder-blue);
    position: absolute;
    z-index: 1;
    right: 72px;
    top: 20px;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    transition: 0.3s ease-in;
}
.saved:hover{
    background-color: var(--dark-blue);
    color: var(--light-azure);
    cursor: pointer;
}

.linked {
    color: white;
    background-color: var(--g-70);
    border: 1px solid transparent;
    position: absolute;
    z-index: 1;
    right: 20px;
    top: 20px;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    transition: 0.3s ease-in;
}

.article>.bg-img>.save-img:hover {
    background-color: var(--powder-blue);
    color: var(--dodger-blue);
    cursor: pointer;
}

.article>.bg-img>.link-img:hover {
    color: var(--g-20);
    background-color: var(--g-90);
    border: 1px solid #CBCBCB;
}

.article>.bg-img>.saved>span,
.article>.bg-img>.linked>span {
    display: none;
    transition: 0.3s ease;
}

.article>.bg-img>.saved:hover span,
.article>.bg-img>.linked:hover span {
    display: block;
}

.article>.article-info>a {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 30px;
    padding: 30px 20px;
    height: 100%;
    transition: transform .3s, visibility .3s ease-in;
}

.article:hover {
    box-shadow: var(--shadow-gray);
}

.article>.article-info>a>div:first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 35px;
}

.article>.article-info>a>div>.article-title {
    padding: 0px 15px;
    color: var(--powder-blue);
    font-size: 17px;
    border-radius: 4px;
    font-weight: 500;
    display: flex;
    align-items: center;
}


.article>.article-info>a>h3 {
    color: var(--g-10);
    font-size: 21px;
    font-weight: 600;
    text-align: left;
}

.article>.article-info>a>.article-descp {
    font-size: 17px;
    line-height: 1.5;
    text-align: left;
    font-weight: 300;
    color: var(--g-10);
}

.author-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: auto;
    z-index: 0;
    position: absolute;
    top: 20px;
    color: white;
    left: 20px;
}

.author-info>p {
    display: flex;
    align-items: center;
    gap: 5px;
    color: white;
    font-weight: 400;
}

.author-info>p>img {
    width: 41px;
    height: 41px;
    border-radius: 50%;
    /* border: 1px solid var(--light-azure); */
}

.article>.bg-img>.time {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    position: absolute;
    bottom: 0px;
    z-index: 0;
    width: 100%;
    background: linear-gradient(0deg, #000000b0 20%, #ffffff00 100%);
}

.article>.bg-img>.time>p {
    font-size: 17px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    gap: 5px;
    color: white;
}

.article>.bg-img>.time>p>svg {
    font-size: 17px;
}

.article>.article-info {
    height: 100%;
    border-radius: 0px 0px 8px 8px;
}

.article>.article-info>a>.article-reaction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 16px; */
    margin-top: auto;
    width: 100%;
    background-color: transparent;
    border: 2px solid #4D4D4D33;
    border-radius: 8px;
    /* height: 32px; */
    color: var(--g-20);
    padding: 10px 32px;
}

 .article>.article-info>a>.article-reaction>div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 68px;
    /* height: 35px; */
    font-size: 12px;
}
/*
.article>.article-info>a>.article-reaction>.view {
    background-color: #00669915;
    color: var(--ocean-blue);
}

.article>.article-info>a>.article-reaction>.like {
    background-color: #99000015;
    color: var(--red-like);
}

.article>.article-info>a>.article-reaction>.share {
    background-color: #00CC8820;
    color: var(--dark-green);
} */

.article>.article-info>a>.article-reaction>.share>svg {
    transform: rotateY(180deg);
}


@media (min-width : 0px) and (max-width : 470px) {
    .article>.article-info>a>div>.article-title {
        font-size: 14px;
    }
}
@media (min-width : 470px) and (max-width : 1300px) {
    /* .article {
        max-width: 100%;
    } */
}