.reviews {
    padding: 45px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 45px;
}
.reviews>.reviews-header{
    text-align: center;
    color: var(--dark-cyan);
}

@media (min-width : 0px) and (max-width : 470px) {
    .reviews>.reviews-header{
        font-size: 25PX;
        text-align: left;
        padding: 0px 20px;
    }
}

@media (min-width : 470px) and (max-width : 1300px) {
    .reviews>.reviews-header{
        font-size: 40PX;
        text-align: center;
        padding: 0px 70px;
        min-width: 600px;
    }
}