/* style when the location is the main page */

.nav-bar {
    height: 90px;
    display: flex;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 5px 0px var(--silver-gray);
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
}

.nav-bar>.container {
    display: flex;
    max-width: var(--section-container-max-width);
    justify-content: space-between;
    width: 100%;
}

.nav-bar>.container>.list>ul {
    list-style: none;
    display: flex;
    gap: 50px;
    align-items: center;
    margin: 0px;
}

.list>ul>.link-container {
    display: flex;
    align-items: center;
    gap: 70px;
}

.list>ul>.sign-container {
    display: flex;
    align-items: center;
    gap: 30px;
}

.nav-bar>.container>.list>ul>.link-container>li {
    font-family: work sans;
    font-size: 20px;
}

.nav-bar>.container>.list>ul>.link-container>li>.link {
    padding: 10px 0px;
    color: var(--g-20);
    transition: 0.3s ease;
    border: 2px solid transparent;
    white-space: nowrap;
}

#try-theme {
    list-style: none;
}

#try-theme>button {
    padding: 10px 20px;
    background-color: var(--powder-blue);
    color: var(--ocean-blue);
    border: 1px solid transparent;
    border-radius: 8px;
    height: 44px;
    font-size: 20px;
    cursor: pointer;
    transition: 0.3s ease;
}

#try-theme>button:hover {
    background-color: var(--sky-blue);
    color: var(--dark-cyan);
    border: 1px solid var(--dark-cyan);

}

#try-theme>button.active {
    background-color: var(--ocean-blue);
    color: var(--powder-blue);
    border: 1px solid var(--ocean-blue);
    box-shadow: 0px 3px 5px 0px gray;
}

#sign-theme>button {
    background-color: var(--light-aqua);
    border: 1px solid transparent;
    border-radius: 8px;
    height: 44px;
    font-size: 20px;
    cursor: pointer;
    transition: 0.3s ease;
}
#sign-theme>button>a{
    color: var(--dark-green);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
}

#sign-theme>button:hover {
    background-color: var(--mint-green);
    border: 1px solid var(--green-cyan);
}

#sign-theme>button.active {
    background-color: var(--jungle-green);
    color: var(--light-aqua);
    border: 1px solid transparent;
    box-shadow: 0px 3px 5px 0px gray;
}

.nav-bar>.container>.phone-list {
    display: none;
}


/* style when the location is the blog page */

.blog-nav-bar {
    display: flex;
    width: 100%;
    max-width: var(--section-container-max-width);
    align-items: center;
    justify-content: space-between;
}

.blog-nav-bar>.logo>a>p {
    font-size: 17px;
    color: gray;
    display: flex;
    gap: 10px;
    align-items: baseline;
}

.blog-nav-bar>.list>ul {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 30px;
    font-size: 20px;
}

.blog-nav-bar>.list>ul>li>.link {
    padding: 10px 20px;
    color: var(--g-10);
    transition: 0.3s ease;
    border: 2px solid transparent;
    white-space: nowrap;
    /* border-radius: 8px; */
}

.blog-nav-bar>.phone-list {
    display: none;
}

/* .blog-nav-bar>.list>ul>li>.link:hover{
    color: var(--light-blue);
    background-color: var(--powder-blue);
    border: 1px solid var(--dodger-blue);
} */
.list>ul>li>.link.active {
    color: var(--dodger-blue);
}

.list>ul>li>.link:hover,
.nav-bar>.container>.list>ul>.link-container>li>.link:hover {
    color: var(--dodger-blue);
    border-bottom: 2px solid var(--light-blue);
    border-radius: 0px;
}

.list>ul>li>.link.active:hover {
    color: var(--dodger-blue);
    /* background-color: var(--ocean-blue);
    box-shadow: 0px 3px 5px 0px gray;
    border-radius: 8px;*/
    border-bottom: 2px solid transparent;
}


@media (min-width : 0px) and (max-width : 470px) {
    .nav-bar {
        height: 60px;
        z-index: 3;
        align-items: center;
    }

    .nav-bar>.container {
        /* position: relative; */
        align-items: center;
    }

    .nav-bar>.container>.list {
        display: none;
    }

    .nav-bar>.container>.logo {
        width: 110px;
        height: 20px;
    }

    .nav-bar>.container>.logo>a {
        width: 100%;
    }

    .nav-bar>.container>.logo>a>img {
        width: 100%;
    }

    .nav-bar>.container>.phone-list {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    #try-theme>button {


        height: 40px;
        font-size: 14px;
        font-weight: 500;
    }

    .nav-bar>.container>.phone-list>.list-icon {
        cursor: pointer;
    }

    .nav-bar>.container>.phone-list>.phone-list-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 80px;
        right: 5%;
        left: 5%;
        min-width: 300px;
        width: 90%;
        background-color: white;
        border-radius: 8px;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.sign-container {
        display: flex;
        gap: 10px;
        padding: 10px;
        justify-content: center;
        border-bottom: 2px solid #D9D9D9;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.sign-container>li {
        list-style: none;
        width: 100%;
    }

    #sign-theme>button {
        width: 100%;
    }
    #sign-theme>button>a {
        width: 100%;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container {
        list-style: none;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li {
        height: 49px;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #D9D9D9;
        padding: 10px;
        background-color: white;
        transition: 0.3s ease-in-out;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li:hover {
        background-color: var(--dodger-blue);
        cursor: pointer;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li:last-child {
        border-bottom: none;
        border-radius: 0px 0px 8px 8px;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li>a {
        font-size: 14px;
        color: var(--g-10);
        transition: 0.3s ease;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li:hover a {
        color: white;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li>a>svg {
        width: 26px;
        height: 26px;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li:hover a>svg>path {
        stroke: white;
    }


    .blog-nav-bar>.list {
        display: none;
    }

    .blog-nav-bar>.phone-list {
        display: block;
        cursor: pointer;
    }

    .blog-nav-bar>.phone-list>.phone-list-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 80px;
        right: 5%;
        left: 5%;
        min-width: 300px;
        width: 90%;
        background-color: white;
        border-radius: 8px;
    }

    .blog-nav-bar>.phone-list>.phone-list-container>.link-container {
        list-style: none;
        border-radius: 8px 8px 0px 0px;
    }

    .blog-nav-bar>.phone-list>.phone-list-container>.link-container>li {
        height: 49px;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #D9D9D9;
        padding: 10px;
        background-color: white;
        transition: 0.3s ease-in-out;
    }

    .blog-nav-bar>.phone-list>.phone-list-container>.link-container>li:hover {
        background-color: var(--dodger-blue);
        cursor: pointer;
    }
    .blog-nav-bar > .phone-list > .phone-list-container > .link-container > li:first-child{
        border-radius: 8px 8px 0px 0px;
    }
    .blog-nav-bar>.phone-list>.phone-list-container>.link-container>li:last-child {
        border-bottom: none;
        border-radius: 0px 0px 8px 8px;
    }

    .blog-nav-bar>.phone-list>.phone-list-container>.link-container>li>a {
        font-size: 14px;
        color: var(--g-10);
        transition: 0.3s ease;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .blog-nav-bar>.phone-list>.phone-list-container>.link-container>li:hover a {
        color: white;
    }

    .blog-nav-bar>.phone-list>.phone-list-container>.link-container>li:hover a>svg>path {
        fill: white;
    }
}


@media (min-width : 470px) and (max-width : 1300px) {
    .nav-bar {
        height: 60px;
        z-index: 3;
    }

    .nav-bar>.container {
        /* position: relative; */
        align-items: center;
    }

    .nav-bar>.container>.list {
        display: none;
    }

    .nav-bar>.container>.logo {
        width: 110px;
        height: 20px;
    }

    .nav-bar>.container>.logo>a {
        width: 100%;
    }

    .nav-bar>.container>.logo>a>img {
        width: 100%;
    }

    .nav-bar>.container>.phone-list {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .nav-bar>.container>.phone-list>.list-icon {
        cursor: pointer;
    }

    .nav-bar>.container>.phone-list>.phone-list-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 108px;
        right: 10%;
        left: 5%;
        min-width: 430px;
        width: 90%;
        background-color: white;
        border-radius: 8px;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.sign-container {
        display: flex;
        gap: 10px;
        padding: 10px;
        justify-content: center;
        border-bottom: 2px solid #D9D9D9;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.sign-container>li {
        list-style: none;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container {
        list-style: none;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li {
        height: 49px;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #D9D9D9;
        padding: 10px;
        background-color: white;
        transition: 0.3s ease-in-out;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li:hover {
        background-color: var(--dodger-blue);
        cursor: pointer;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li:last-child {
        border-bottom: none;
        border-radius: 0px 0px 8px 8px;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li>a {
        font-size: 14px;
        color: var(--g-10);
        transition: 0.3s ease;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li:hover a {
        color: white;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li>a>svg {
        width: 26px;
        height: 26px;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.link-container>li:hover a>svg>path {
        stroke: white;
    }

    .blog-nav-bar>.list {
        display: none;
    }

    .blog-nav-bar>.phone-list {
        display: block;
        cursor: pointer;
    }

    .blog-nav-bar>.phone-list>.phone-list-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 108px;
        right: 10%;
        left: 10%;
        min-width: 300px;
        width: 90%;
        background-color: white;
        border-radius: 8px;
    }

    .blog-nav-bar>.phone-list>.phone-list-container>.link-container {
        list-style: none;
        border-radius: 8px;
    }

    .blog-nav-bar>.phone-list>.phone-list-container>.link-container>li {
        height: 49px;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #D9D9D9;
        padding: 10px;
        background-color: white;
        transition: 0.3s ease-in-out;
    }

    .nav-bar>.container>.phone-list>.phone-list-container>.sign-container>li {
        list-style: none;
        width: 100%;
    }

    #sign-theme>button {
        width: 100%;
    }

    .blog-nav-bar>.phone-list>.phone-list-container>.link-container>li:hover {
        background-color: var(--dodger-blue);
        cursor: pointer;
    }
    .blog-nav-bar > .phone-list > .phone-list-container > .link-container > li:first-child{
        border-radius: 8px 8px 0px 0px;
    }
    .blog-nav-bar>.phone-list>.phone-list-container>.link-container>li:last-child {
        border-bottom: none;
        border-radius: 0px 0px 8px 8px;
    }

    .blog-nav-bar>.phone-list>.phone-list-container>.link-container>li>a {
        font-size: 14px;
        color: var(--g-10);
        transition: 0.3s ease;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .blog-nav-bar>.phone-list>.phone-list-container>.link-container>li:hover a {
        color: white;
    }

    .blog-nav-bar>.phone-list>.phone-list-container>.link-container>li:hover a>svg>path {
        fill: white;
    }
}