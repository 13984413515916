.subscribe {
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 60px 0px 0px 0px;
}

.subscribe>.sub-content {
    background-color: var(--powder-blue);
    display: flex;
    align-items: center;
    padding: 0px 200px;
    gap: 60px;
}

.subscribe>.sub-content>div:first-child {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.subscribe>.sub-content>div:first-child>div{
    color: var(--dark-blue);
    text-align: left;
    padding-bottom: 10px;
}

.subscribe>.sub-content>div:first-child>p {
    font-size: 17px;
    color: var(--dark-blue);
    list-style: 1.5;
    text-align: left;
    padding-bottom: 30px;
}

.subscribe>.sub-content>div:first-child>input {
    background-color: white;
    border: 1px solid var(--silver-gray);
    height: 50px;
    border-radius: 8px;
    padding: 6px;
    font-size: 17px;
}
.subscribe>.sub-content>div:first-child>input:focus{
    border: 1px solid var(--light-azure);
}

/* .subscribe>.sub-content>.sub-img>.img {
    display: block;
    transition: 0.3s ease;
}

.subscribe>.sub-content>.sub-img>.hovered {
    display: none;
    transition: 0.3s ease;
}

.subscribe>.sub-content>.sub-img:hover .img {
    display: none;
}

.subscribe>.sub-content>.sub-img:hover .hovered {
    display: block;
} */


@media (min-width : 0px) and (max-width : 470px) {
    .subscribe>.sub-content {
        padding: 60px 20px;
        gap: 45px;
        flex-direction: column-reverse;
    }
    .subscribe>.sub-content>.sub-img {
        width: 355px;
        height: 200px;
    }
    .subscribe>.sub-content>.sub-img>.img {
        width: 100%;
        height: 100%;
    }
    .subscribe>.sub-content>div:first-child>.sub-title{
        font-size: 30px;
    }
    .subscribe>.sub-content>div:first-child>p {
        font-size: 14px;
    }
}
@media (min-width : 470px) and (max-width : 1300px) {
    .subscribe>.sub-content {
        padding: 60px 20px;
        gap: 45px;
        flex-direction: column-reverse;
    }
    .subscribe>.sub-content>.sub-img {
        width: 768px;
        height: 588px;
    }
    .subscribe>.sub-content>.sub-img>.img {
        width: 100%;
        height: 100%;
    }
    .subscribe>.sub-content>div:first-child>.sub-title{
        font-size: 40px;
    }
    .subscribe>.sub-content>div:first-child>p {
        font-size: 17px;
    }
}