.news {
    padding: 60px 90px;
    background: linear-gradient(180deg, #000000 0%, var(--dark-blue) 100%);
    display: flex;
    gap: 50px;
}

.news-blog {
    width: 75%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    gap: 20px;
}

.news>.news-blog>*:first-child {
    grid-row: 1 /span 2;
}

@media (min-width : 0px) and (max-width : 470px) {
    .news {
        padding: 20px 20px 30px 20px;
        display: flex;
        flex-direction: column-reverse;
    }

    .news-blog {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    /* .news>.choice{
        display: none;
    } */

}

@media (min-width : 470px) and (max-width : 1300px) {
    .news{
        position: relative;
    }
    .news-blog {
        width: 100%;
        grid-template-columns: 1fr 1fr;
    }
    .news>.news-blog>*:nth-child(2) , .news>.news-blog>*:nth-child(3) {
        grid-row: 3;
    }
    .news>.news-blog>*:nth-child(4) , .news>.news-blog>*:nth-child(5) {
        grid-row: 4;
    }
}