.one-author {
    display: flex;
    flex-direction: column;
}

.one-author>.one-author-header {
    position: relative;
    padding: 0px 0px 200px 0px;
}

.one-author>.one-author-header>.bg-image {
    background: url(/public/images/bg-author.png) no-repeat;
    height: 405px;
    width: 100%;
}

.one-author>.one-author-header>.author-image {
    position: absolute;
    top: 271px;
    display: flex;
    padding: 0px 200px;
    width: 100%;
    align-items: center;
    gap: 20px;
}

.one-author>.one-author-header>.author-image>.top-border {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 460px;
    top: -10px;
}

.one-author>.one-author-header>.author-image>.bottom-border {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 194px;
    top: 259px;
}

.one-author>.one-author-header>.author-image>img {
    width: 271px;
    height: 271px;
    object-fit: cover;
}

.one-author>.one-author-header>.author-image>.author-name {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 100px;
}

.one-author>.one-author-header>.author-image>.author-name>div:first-child {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.one-author>.one-author-header>.author-image>.author-name>div:first-child>h1 {
    color: var(--g-10);
    font-size: 45px;
    line-height: 1.1;
    font-weight: 500;
}

.one-author>.one-author-header>.author-image>.author-name>div:first-child>p {
    color: var(--g-30);
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
}

.one-author>.one-author-header>.author-image>.author-name>div:first-child>p>span {
    display: flex;
    align-items: center;
    gap: 5px;
}

.one-author>.one-author-header>.author-image>.author-name>div:last-child>.btn {
    background-color: var(--sky-blue);
    color: var(--ocean-blue);
    height: 46px;
    padding: 10px 20px;
    border: 1px solid transparent;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    transition: 0.3s ease;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.one-author>.one-author-header>.author-image>.author-name>div:last-child>.btn:hover {
    color: var(--powder-blue);
    background-color: var(--dodger-blue);
}

.one-author>.one-author-header>.author-image>.author-name>div:last-child>.button-active {
    background-color: var(--dark-cyan);
    color: var(--powder-blue);
}

.one-author>.one-author-content {
    display: flex;
    padding: 0px 100px 60px 100px;
    gap: 50px;
    flex-wrap: wrap;
}

.one-author>.one-author-content>div:first-child {
    max-width: 48%;
    width: 100%;
    text-align: justify;
    position: relative;
    flex-shrink: 0;
}

.one-author>.one-author-content>div:first-child>.author-line {
    width: 2px;
    height: 97%;
    position: absolute;
    background: linear-gradient(180deg, #33BBFF 0%, #33BBFF 10%, #ffffff 100%);
    left: -18px;
    top: 15px;
    z-index: -1;
}

.one-author>.one-author-content>div:first-child>ul>li {
    padding-left: 100px;
    font-size: 25px;
    line-height: 1.2;
    font-weight: 500;
    color: var(--g-10);
}

.one-author>.one-author-content>div:first-child>ul>p {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    color: var(--g-10);
    padding: 20px 0 20px 100px;
}

.one-author>.one-author-content>div:first-child>ul>ol {
    padding-left: 125px;
}

.one-author>.one-author-content>div:first-child>ul>ol>li {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    color: var(--g-10);
    padding-top: 20px;
}

.one-author>.one-author-content>div:first-child>p {
    padding-left: 100px;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    color: var(--g-10);
    padding-top: 20px;
}

.one-author>.one-author-content>div:last-child {
    width: 48%;
    padding: 30px;
    border: 2px solid var(--sky-blue);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    text-align: justify;
}

.one-author>.one-author-content>div:last-child>div {
    color: var(--g-10);
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
}

.one-author>.one-author-content>div:last-child>div>span {
    color: var(--g-10);
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
}

.one-author>.one-author-content>div:last-child>div>p {
    color: var(--dark-cyan);
    font-size: 20px;
    line-height: 2;
    font-weight: 500;
}

.one-author>.one-author-content>div:last-child>div>p.awards {
    color: var(--dodger-blue);
    display: flex;
    align-items: center;
    gap: 5px;
}


.one-author>.one-author-content>div:last-child>.biography>p {
    color: var(--g-10);
    font-size: 20px;
    line-height: 2;
    font-weight: 400;
    padding: 10px 0 15px 0px;
}


@media (min-width : 0px) and (max-width : 470px) {
    .one-author>.one-author-header>.bg-image {
        height: 180px;
        background-size: 630px;
    }

    .one-author>.one-author-header {
        position: relative;
        padding: 0px 0px 10px 0px;
    }

    .one-author>.one-author-header>.author-image {
        position: relative;
        top: 0px;
        display: flex;
        padding: 40px 20px;
        width: 100%;
        align-items: center;
        gap: 20px;
    }

    .one-author>.one-author-header>.author-image>img {
        width: 144px;
        height: 144px;
        object-fit: cover;
    }

    .one-author>.one-author-header>.author-image>.top-border {
        width: 16px;
        height: 16px;
        position: absolute;
        left: 155px;
        top: 29px;
    }

    .one-author>.one-author-header>.author-image>.bottom-border {
        width: 16px;
        height: 16px;
        position: absolute;
        left: 12px;
        top: 175px;
    }

    .one-author>.one-author-header>.author-image>.author-name {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 0px;
        gap: 10px;
    }

    .one-author>.one-author-header>.author-image>.author-name>div:first-child {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .one-author>.one-author-header>.author-image>.author-name>div:first-child>h1 {
        color: var(--g-10);
        font-size: 20px;
        line-height: 1.1;
        font-weight: 500;
    }

    .one-author>.one-author-header>.author-image>.author-name>div:first-child>p {
        color: var(--g-30);
        font-size: 14px;
        line-height: 1.5;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
    }

    .one-author>.one-author-header>.author-image>.author-name>div:first-child>p>span {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .one-author>.one-author-header>.author-image>.author-name>div:last-child>.btn {
        padding: 12px 30px;
    }

    .one-author>.one-author-content {
        flex-direction: column-reverse;
        padding: 15px 20px 15px 20px;
        gap: 50px;
        flex-wrap: wrap;
    }

    .one-author>.one-author-content>div:last-child {
        width: 100%;
        padding: 15px;
        gap: 30px;
    }

    .one-author>.one-author-content>div:last-child>div {
        font-size: 14px;
    }

    .one-author>.one-author-content>div:last-child>div>span {
        font-size: 14px;
    }

    .one-author>.one-author-content>div:last-child>div>p {
        font-size: 14px;
    }

    .one-author>.one-author-content>div:last-child>.biography>p {
        font-size: 14px;
    }

    .one-author>.one-author-content>div:first-child>.author-line {
        display: none;
    }

    .one-author>.one-author-content>div:first-child {
        max-width: 100%;
    }

    .one-author>.one-author-content>div:first-child>ul>li {
        padding-left: 0px;
        font-size: 18px;
        list-style: none;
    }

    .one-author>.one-author-content>div:first-child>ul>p {
        font-size: 14px;
        padding: 30px 0px;
    }

    .one-author>.one-author-content>div:first-child>ul>ol {
        padding-left: 20px;
    }

    .one-author>.one-author-content>div:first-child>ul>ol>li {
        font-size: 14px;
    }

    .one-author>.one-author-content>div:first-child>p {
        padding-left: 0px;
        font-size: 14px;
    }
}



@media (min-width : 470px) and (max-width : 1300px) {
    .one-author>.one-author-header>.author-image {
        padding: 0px 70px;
    }

    .one-author>.one-author-header>.author-image>.top-border {

        left: 329px;

    }

    .one-author>.one-author-header>.author-image>.bottom-border {

        left: 64px;
        top: 257px;

    }

    .one-author>.one-author-header>.author-image>.author-name {
        align-items: flex-end;
    }

    .one-author>.one-author-header>.author-image>.author-name>div:first-child {
        gap: 10px;
    }

    .one-author>.one-author-header>.author-image>.author-name>div:first-child>p {
        gap: 10px;
        flex-direction: column;
    }
    .one-author > .one-author-header > .author-image > .author-name > div:first-child > h1{
        font-size: 25px;
    }
    .one-author > .one-author-content{
        flex-direction: column-reverse;
        padding: 0px 70px;
    }
    .one-author > .one-author-content > div:last-child{
        width: 100%;
    }
    .one-author > .one-author-content > div:first-child{
        max-width: 100%;
    }
    .one-author > .one-author-content > div:first-child > .author-line{
        display: none;
    }
    .one-author > .one-author-content > div:first-child > ul > li{
        list-style: none;
        padding-left: 0px;
        font-size: 24px;
    }
    .one-author > .one-author-content > div:first-child > ul > p{
        font-size: 16px;
        padding: 20px 0px;
    }
    .one-author > .one-author-content > div:first-child > ul > ol{
        padding-left: 30px;
    }
    .one-author > .one-author-content > div:first-child > ul > ol>li{
        font-size: 16px;
    }
    .one-author > .one-author-content > div:first-child > p{
        padding-left: 0px;
        font-size: 16px;
    }
}