.menu-icon {
    width: 30px;
    height: 20px;
    position: relative;
    cursor: pointer;
  }
  
  .bar {
    width: 100%;
    height: 3px;
    border-radius: 10px;
    background-color: var(--light-blue);
    position: absolute;
    transition: transform 0.3s ease;
  }
  
  .bar:nth-child(1) {
    top: 0;
  }
  
  .bar:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  
  .bar:nth-child(3) {
    bottom: 0;
  }
  
  .menu-icon.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .menu-icon.open .bar:nth-child(2) {
    opacity: 0;
  }
  
  .menu-icon.open .bar:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
  }
  