/* title style */
.ovoky-text-style-H0-title-hero {
    font-size: 80px;
    line-height: 1.2;
    font-weight: 600;
}

.ovoky-text-style-H0-title-hero-2 {
    font-size: 64px;
    line-height: 1.2;
    font-weight: 500;
}

.ovoky-text-style-title-H1 {
    font-size: 45px;
    line-height: 1.1;
    font-weight: 500;
}

.ovoky-text-style-title-H2 {
    font-size: 40px;
    line-height: 1.1;
    font-weight: 500;
}

.ovoky-text-style-title-H3 {
    font-size: 25px;
    line-height: 1.2;
    font-weight: 500;
}

/* lables and tags style */
.ovoky-text-style-p2-small {
    font-size: 17px;
    line-height: 1.5;
    font-weight: 400;
}

.ovoky-text-style-lables {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
}

/* paragraph style */

.ovoky-text-style-body-small {
    font-size: 17px;
    line-height: 1.5;
    font-weight: 500;
}

.ovoky-text-style-body-cards {
    font-size: 15px;
    line-height: 1.5;
    font-weight: 400;
}

.ovoky-text-style-p1-body {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
}

.ovoky-text-style-p1-body-medium {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
}


.ovoky-text-style-cta {
    font-size: 20px;
    line-height: 1.3;
    font-weight: 600;
}