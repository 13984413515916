.modal {
    position: fixed;
    top: 100px;
    left: 300px;
    display: flex;
    box-shadow: 0px 0px 30px 3px #808080;
    height: 675px;
    overflow: hidden;
    border: 20px solid rgb(231, 231, 231, 0.5);
    border-radius: 8px;
    width: 70%;
    z-index: 3;
}

.modal-phone {
    display: none;
}

.modal>.image {
    width: 60%;
    height: 100%;
}

.modal>.image>img {
    width: 100%;
    height: 100%;
}

.modal>.text {
    background-color: white;
    padding: 60px 40px;
    width: 40%;
    display: flex;
    flex-direction: column;
}

.modal>.text>h2 {
    color: #818181;
    font-size: 25px;
    line-height: 1.2;
    text-align: left;
    padding-bottom: 50px;
    font-weight: 500;
}

.modal>.text>h2>span {
    color: var(--light-blue);
}

.modal>.text>h3 {
    color: var(--dark-blue);
    font-size: 25px;
    line-height: 1.2;
    font-weight: 400;
    text-align: left;
    padding-bottom: 2px;
}

.modal>.text>.under-line {
    background-color: var(--light-blue);
    width: 55px;
    height: 2px;
    margin-bottom: 20px;
}

.modal>.text>form input {
    border: 1px solid var(--silver-gray);
    border-radius: 8px;
    height: 40px;
    padding: 10px;
}

.modal>.text>.sign-in-form>input {
    border: 1px solid var(--silver-gray);
    border-radius: 8px;
    height: 50px;
    padding: 10px;
    font-size: 20px;
}

.modal>.text>form {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
    width: 100%;
}

.modal>.text>form>p {
    font-size: 17px;
    color: #999999;
    text-align: left;
    padding-bottom: 20px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.modal>.text>form>p:hover {
    text-decoration: underline;
    color: var(--light-blue);
}

.modal>.text>form>div {
    display: flex;
    gap: 16px;
    justify-content: space-between;
}

.modal>.text>form>div>input {
    width: 50%;
}

.modal>.text>form>div>input:focus,
.modal>.text>form input:focus {
    border: 1px solid var(--light-blue);
}

.modal>.text>form>div>input:hover,
.modal>.text>form input:hover {
    border: 1px solid var(--light-blue);
}

.modal>.text>form>.button-link>.button {
    border-radius: 6px;
}

.modal>.text>.break {
    display: inline-flex;
    padding-bottom: 20px;
    /* padding-left: 21%; */
    align-self: center;
}

.modal>.text>.break>span {
    width: 100px;
    height: 1px;
    background: #CCCCCC;
    display: inline-block;
    text-align: center;
    margin: 8px;
}

.modal>.text>.break>div {
    color: #525252;
    display: inline;
}

.modal>.text>ul {
    display: flex;
    justify-content: center;
    list-style: none;
    gap: 20px;

}

.modal>.text>ul>li {
    cursor: pointer;
}

.modal>.text>.button-account {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 10px;
}

.modal>.text>.button-account>button {
    color: var(--electric-blue);
    font-size: 17px;
    background-color: transparent;
    cursor: pointer;
}

@media (min-width : 0px) and (max-width : 470px) {
    .modal {
        display: none;
    }

    .modal-phone {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 60px;
        left: 0;
        background-color: white;
        z-index: 2;
        padding: 40px;
        gap: 20px;
        justify-content: flex-start;
        align-items: flex-start;
        transform: translateX(-100%);
        transition: 0.3s ease-in-out;
    }

    .modal-phone.open {
        transform: translateX(0%);
    }

    .modal-phone>.back-button {
        width: 45px;
        height: 45px;
        background-color: var(--powder-blue);
        color: var(--electric-blue);
        border-radius: 6px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
    }

    .modal-phone>.back-button>svg>path {
        stroke: var(--electric-blue);
    }

    .modal-phone>.back-button:hover {
        background-color: var(--light-azure);
    }

    .modal-phone>.back-button:hover svg>path {
        stroke: var(--dodger-blue);
    }

    .modal-phone>.text {
        background-color: white;
        /* padding: 60px 40px; */
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .modal-phone>.text>h2 {
        color: #818181;
        font-size: 25px;
        line-height: 1.2;
        text-align: left;
        padding-bottom: 50px;
        font-weight: 500;
    }

    .modal-phone>.text>h2>span {
        color: var(--light-blue);
    }

    .modal-phone>.text>h3 {
        color: var(--dark-blue);
        font-size: 25px;
        line-height: 1.2;
        font-weight: 400;
        text-align: left;
        padding-bottom: 2px;
    }

    .modal-phone>.text>.under-line {
        background-color: var(--light-blue);
        width: 55px;
        height: 2px;
        margin-bottom: 40px;
    }

    .modal-phone>.text>form input {
        border: 1px solid var(--silver-gray);
        border-radius: 8px;
        height: 56px;
        padding: 10px;
        font-size: 20px;
    }

    .modal-phone>.text>.sign-in-form>input {
        border: 1px solid var(--silver-gray);
        border-radius: 8px;
        height: 56px;
        padding: 10px;
        font-size: 20px;
    }

    .modal-phone>.text>form {
        width: 400px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 20px;
        width: 100%;
    }

    .modal-phone>.text>form>p {
        font-size: 17px;
        color: #999999;
        text-align: left;
        padding-bottom: 20px;
    }

    .modal-phone>.text>form>div {
        display: flex;
        gap: 16px;
        justify-content: space-between;
    }

    .modal-phone>.text>form>div>input {
        width: 50%;
    }

    .modal-phone>.text>form>div>input:focus,
    .modal-phone>.text>form input:focus {
        border: 1px solid var(--light-blue);
    }

    .modal-phone>.text>form>div>input:hover,
    .modal-phone>.text>form input:hover {
        border: 1px solid var(--light-blue);
    }

    .modal-phone>.text>form>.button-link>.button {
        border-radius: 6px;
    }

    .modal-phone>.text>.break {
        display: inline-flex;
        padding-bottom: 20px;
        align-self: center;
    }

    .modal-phone>.text>.break>span {
        width: 100px;
        height: 1px;
        background: #CCCCCC;
        display: inline-block;
        text-align: center;
        margin: 8px;
    }

    .modal-phone>.text>.break>div {
        color: #525252;
        display: inline;
    }

    .modal-phone>.text>ul {
        display: flex;
        justify-content: center;
        list-style: none;
        gap: 20px;

    }

    .modal-phone>.text>ul>li {
        cursor: pointer;
    }

    .modal-phone>.text>.button-account {
        display: flex;
        width: 100%;
        justify-content: center;
        padding-top: 10px;
    }

    .modal-phone>.text>.button-account>button {
        color: var(--electric-blue);
        font-size: 17px;
        background-color: transparent;
        cursor: pointer;
    }
}

@media (min-width : 470px) and (max-width : 1300px) {
    .modal {
        display: none
    }

    .modal-phone {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 60px;
        left: 0;
        background-color: white;
        z-index: 2;
        padding: 40px;
        gap: 20px;
        justify-content: flex-start;
        align-items: flex-start;
        transform: translateX(-100%);
        transition: 0.3s ease-in-out;
    }

    .modal-phone.open {
        transform: translateX(0%);
    }

    .modal-phone>.back-button {
        width: 45px;
        height: 45px;
        background-color: var(--powder-blue);
        color: var(--electric-blue);
        border-radius: 6px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
    }

    .modal-phone>.back-button>svg>path {
        stroke: var(--electric-blue);
    }

    .modal-phone>.back-button:hover {
        background-color: var(--light-azure);
    }

    .modal-phone>.back-button:hover svg>path {
        stroke: var(--dodger-blue);
    }

    .modal-phone>.text {
        background-color: white;
        /* padding: 60px 40px; */
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .modal-phone>.text>h2 {
        color: #818181;
        font-size: 25px;
        line-height: 1.2;
        text-align: left;
        padding-bottom: 50px;
        font-weight: 500;
    }

    .modal-phone>.text>h2>span {
        color: var(--light-blue);
    }

    .modal-phone>.text>h3 {
        color: var(--dark-blue);
        font-size: 25px;
        line-height: 1.2;
        font-weight: 400;
        text-align: left;
        padding-bottom: 2px;
    }

    .modal-phone>.text>.under-line {
        background-color: var(--light-blue);
        width: 55px;
        height: 2px;
        margin-bottom: 40px;
    }

    .modal-phone>.text>form input {
        border: 1px solid var(--silver-gray);
        border-radius: 8px;
        height: 56px;
        padding: 10px;
        font-size: 20px;
    }

    .modal-phone>.text>.sign-in-form>input {
        border: 1px solid var(--silver-gray);
        border-radius: 8px;
        height: 56px;
        padding: 10px;
        font-size: 20px;
    }

    .modal-phone>.text>form {
        width: 400px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 20px;
        width: 100%;
    }

    .modal-phone>.text>form>p {
        font-size: 17px;
        color: #999999;
        text-align: left;
        padding-bottom: 20px;
    }

    .modal-phone>.text>form>div {
        display: flex;
        gap: 16px;
        justify-content: space-between;
    }

    .modal-phone>.text>form>div>input {
        width: 50%;
    }

    .modal-phone>.text>form>div>input:focus,
    .modal-phone>.text>form input:focus {
        border: 1px solid var(--light-blue);
    }

    .modal-phone>.text>form>div>input:hover,
    .modal-phone>.text>form input:hover {
        border: 1px solid var(--light-blue);
    }

    .modal-phone>.text>form>.button-link>.button {
        border-radius: 6px;
    }

    .modal-phone>.text>.break {
        display: inline-flex;
        padding-bottom: 20px;
        align-self: center;
    }

    .modal-phone>.text>.break>span {
        width: 100px;
        height: 1px;
        background: #CCCCCC;
        display: inline-block;
        text-align: center;
        margin: 8px;
    }

    .modal-phone>.text>.break>div {
        color: #525252;
        display: inline;
    }

    .modal-phone>.text>ul {
        display: flex;
        justify-content: center;
        list-style: none;
        gap: 20px;

    }

    .modal-phone>.text>ul>li {
        cursor: pointer;
    }

    .modal-phone>.text>.button-account {
        display: flex;
        width: 100%;
        justify-content: center;
        padding-top: 10px;
    }

    .modal-phone>.text>.button-account>button {
        color: var(--electric-blue);
        font-size: 17px;
        background-color: transparent;
        cursor: pointer;
    }
}